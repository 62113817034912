import { Field } from '../extensions/FormikExtension'
import * as Yup from "yup";

export var userEmail = new Field({ Name: "userEmail", InitialValue: "" })
export var userName = new Field({ Name: "userName", InitialValue: "" })
export var role = new Field({ Name: "role", InitialValue: "" })

export const userSchema = Yup.object({
	userEmail: Yup.string()
    .required('Please Enter User Email'),
    
    userName: Yup.string()
    .required('Please Enter User Full Name'),

    role: Yup.string()
    .required("Please Choose a Role"),
})