import React, { useState, useEffect } from 'react';
import { HiOutlineFilter } from 'react-icons/hi';
import { RiArrowDropDownLine } from 'react-icons/ri';
import AttendanceReportTable from './table/AttendanceReportTable';
import ZoneReport from './table/ZoneReport';
import TransactionTable from './table/TransactionTable';
import ManagementApi from '../../Api/ManagementApi';
import { ToastTemplate, ToastType } from '../../notifications/ToastExecutor';

function Reports() {
  const [loading, setLoading] = useState(false);
  const [zoneId, setZoneId] = useState('');
  const [zoneList, setZoneList] = useState([]);
  const [attendanceReport, setAttendanceReport] = useState(false);
  const [zonesReport, setZonesReport] = useState(false);
  const [transactionReport, setTransactionReport] = useState(true);
  const [employeeFilterData, setEmployeeFilterData] = useState([]);

  useEffect(() => {
    async function fillZones() {
      try {
        setLoading(true);
        let res = await ManagementApi.zoneApi.zoneList.call();

        if (res.status) {
          setZoneList(res.data);
        } else {
          new ToastTemplate(ToastType.Error, res.message).execute();
        }
        setLoading(false);
      } catch (err) {
        new ToastTemplate(ToastType.Error, err.message).execute();
        setLoading(false);
      }
    }
    fillZones();
  }, []);

  function ShowElements(value) {
    setTransactionReport(value === 'TransactionReport')
    setAttendanceReport(value === 'AttendanceReport');
    setZonesReport(value === 'ZoneReport');
    setEmployeeFilterData([]);
  }

  async function filterNonSignedEmpolyees(id) {
    setLoading(true);
    try {
      setZoneId(id);
      // let res = await ReportApi.NonSignedOutEmployees.call(id);
      // if(res.status){
      //   setEmployeeFilterData(res.data);
      // }else{
      //   new ToastTemplate(ToastType.Error,res.message).execute()
      // }
    } catch (err) {
      new ToastTemplate(ToastType.Error, err.message).execute();
    }
    setLoading(false);
  }

  return (
    <>
      <div className="container-fluid">
        <div className="report">
          <div className="row">
            <div className="col-9 tap-btn-group">
              <div className="col-9 tap-btn-group">
              <button
                  className={
                    transactionReport
                      ? 'stageBtn active text-white border-none'
                      : 'stageBtn border-none'
                  }
                  onClick={() => {
                    ShowElements('TransactionReport');
                  }}
                >
                  Transaction Report
                </button>
                <button
                  className={
                    attendanceReport
                      ? 'stageBtn active text-white border-none'
                      : 'stageBtn border-none'
                  }
                  onClick={() => {
                    ShowElements('AttendanceReport');
                  }}
                >
                  Attendance Report
                </button>
                <button
                  className={
                    zonesReport
                      ? 'stageBtn active text-white border-none'
                      : 'stageBtn border-none'
                  }
                  onClick={() => {
                    ShowElements('ZoneReport');
                  }}
                >
                  Muster Report
                </button>
              </div>
            </div>
            <div className="col-3 text-end add-btn">
              {attendanceReport || transactionReport ? (
                <button
                  className="filter-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#filterModal"
                >
                  <HiOutlineFilter className="me-2" />
                  Filter
                  <RiArrowDropDownLine className="ms-2" />
                </button>
              )  : zonesReport ? (
                <div className="zone-filter-select" id="filterModal">
                  <select
                    className="form-select"
                    onChange={(e) => {
                      filterNonSignedEmpolyees(e.target.value);
                    }}
                    name=""
                    id=""
                  >
                    <option value="">Select Zone..</option>
                    {zoneList.map((zone) => (
                      <option value={zone?.id}>{zone?.name}</option>
                    ))}
                  </select>
                </div>)
                : <div></div>}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {attendanceReport ? (
                <AttendanceReportTable />
              ) : zonesReport ? (
                <ZoneReport zoneId={zoneId} />
              ) : (
                <TransactionTable />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Reports;
