import React, { useEffect, useState, useRef } from 'react'
import ReactECharts from 'echarts-for-react';
import EChartsReact from 'echarts-for-react';

function Pie(props) {


  const getChartOptions = () => {

    return {
      tooltip: {
        trigger: 'item'
      },

      legend: {
        orient: 'horizontal',
        top: '90%',
        left: 'center',
        icon: 'circle'
      },
      showCount: true,

      series: [{
        name: 'Access From',
        type: 'pie',
        radius: ['50%', '80%'],
        label: {
          show: false,
          position: 'center'

        },

        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: 'bold'
          }
        },
        data: [
          {
            value: props.resourcesDistributionData.inSite, name: 'On Site', itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2, color: "#50CD89"
            }
          },
          {
            value: props.resourcesDistributionData.outSite, name: 'Off Site', itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2, color: "#FFC700"
            }
          },
        ]
      }
      ]
    }
  }






 





  return (
    <div className="w-100">

    
       <EChartsReact 
        option={getChartOptions()}
      /> 

 

    </div>
  )
}

export default Pie
