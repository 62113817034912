import { Field } from '../extensions/FormikExtension'
import * as Yup from "yup";
export var deviceName = new Field({ Name: "deviceName", InitialValue: "" })
export var modelNumber = new Field({ Name: "modelNumber", InitialValue: "" })
export var HWID = new Field({ Name: "HWID", InitialValue: "" })
export var zone = new Field({ Name: "zone", InitialValue: "" })
export var extras = new Field({ Name: "extras", InitialValue: "" })

export const DeviceSchema = Yup.object({
	deviceName: Yup.string()
    .required('Please Enter  device Name'),
  
    modelNumber: Yup.string()
    .required('Please Enter  model Number'),
    HWID: Yup.string()
    .required('Please Enter  HWID'),
    zone: Yup.string()
    .required('Please select  zone'),
    extras: Yup.string()
})