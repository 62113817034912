import axios from "axios";
import ApiUtils from "./ApiUtils";
const ManagementApi = {
  zoneApi: {
    zoneList: {
      default: {
        data: [
          {
            createdTime: "",
            id: "",
            name: "",
          },
        ],
        status: true,
        code: 0,
        message: "",
      },
      call: async () => {
        return new Promise((resolve, reject) => {

          ApiUtils.setAxiosDefaultHeaders(axios);          
          axios
            .get(process.env.REACT_APP_API_URL + `/Zones/List`)
            .then((response) => {
              let res =ApiUtils.handleApiResponse(response);
              resolve(res);
            })
            .catch((error) => {
              reject({
                status: false,
                code: -1,
                message: error.message,
              });
            });
        });
      },
    },
    getZoneById: {
      default: {
        data: { id: "" },
        status: true,
        code: 0,
        message: "",
      },
      call: async (body) => {
        return new Promise((resolve, reject) => {
       
          ApiUtils.setAxiosDefaultHeaders(axios);

          axios
            .get(process.env.REACT_APP_API_URL + `/Zones/GetById/${body}`)
            .then((response) => {
              let res = ApiUtils.handleApiResponse(response);
              resolve(res);
            })
            .catch((error) => {
              reject({
                status: false,
                code: -1,
                message: error.message,
              });
            });
        });
      },

    },
    addZone: {
      default: {
        data: { name: "" },
        status: true,
        code: 0,
        message: "",
      },
      call: async (body) => {
        return new Promise((resolve, reject) => {
          ApiUtils.setAxiosDefaultHeaders(axios);
          axios.post(process.env.REACT_APP_API_URL + `/Zones/Add`, body).then((response) => {
            let res =ApiUtils.handleApiResponse(response);
            
            resolve(res);
          }).catch((error) => {

            reject({
              status: false,
              code: -1,
              message: error.response.data.message,
            });
          });
        });
      },
    },
    updateZone: {
      default: {
        data: {
          name: "",
          id: "",
        },
        status: true,
        code: 0,
        message: "",
      },


      call: async (body) => {
        return new Promise((resolve, reject) => {
         
          ApiUtils.setAxiosDefaultHeaders(axios);

          axios
            .put(process.env.REACT_APP_API_URL + `/Zones/Update`, body)
            .then((response) => {
              let res =ApiUtils.handleApiResponse(response);
              resolve(res);
            })
            .catch((error) => {
              reject({
                status: false,
                code: -1,
                message: error.message,
              });
            });
        });
      },
    },
    deleteZone: {
      default: {
        data: {
          id: "",
        },
        status: true,
        code: 0,
        message: "",
      },
      call: async (body) => {
        return new Promise((resolve, reject) => {
          
          ApiUtils.setAxiosDefaultHeaders(axios);

          axios.delete(process.env.REACT_APP_API_URL + `/Zones/Delete/${body}`)
            .then((response) => {
              let res =ApiUtils.handleApiResponse(response);
              resolve(res);
            })
            .catch((error) => {
              reject({
                status: false,
                code: -1,
                message: error.message,
              });
            });
        });
      },
    },
  },

  deviceApi: {
    deviceList: {
      default: {
        data: [],
        status: true,
        code: 0,
        message: "",
      },
      call: async () => {
        return new Promise((resolve, reject) => {
          
          ApiUtils.setAxiosDefaultHeaders(axios);

          axios.get(process.env.REACT_APP_API_URL + `/Devices/List`)
            .then((response) => {
              let res =ApiUtils.handleApiResponse(response);
              resolve(res);
            })
            .catch((error) => {
              reject({
                status: false,
                code: -1,
                message: error.message,
              });
            });
        });
      },
    },
    getDeviceById: {
      default: {
        data: { id: "" },
        status: true,
        code: 0,
        message: "",
      },
      call: async (body) => {
        return new Promise((resolve, reject) => {
    

          ApiUtils.setAxiosDefaultHeaders(axios);

          axios
            .get(process.env.REACT_APP_API_URL + `/Devices/GetById/${body}`)
            .then((response) => {
              let res =ApiUtils.handleApiResponse(response);
              resolve(res);
            })
            .catch((error) => {
              reject({
                status: false,
                code: -1,
                message: error.message,
              });
            });
        });
      },

    },
    addDevice: {
      default: {
        data: {},
        status: true,
        code: 0,
        message: "",
      },
      call: async (body) => {
        return new Promise((resolve, reject) => {
        

          ApiUtils.setAxiosDefaultHeaders(axios);

          axios.post(process.env.REACT_APP_API_URL + `/Devices/Add`, body).then((response) => {
            let res =ApiUtils.handleApiResponse(response);
       
            resolve(res);
          }).catch((error) => {
            
            reject({
              status: false,
              code: -1,
              message: error.response.data.message,
            });
          });
        });
      },
    },
    updateDevice: {
      default: {
        data: {
          name: "",
          id: "",
        },
        status: true,
        code: 0,
        message: "",
      },


      call: async (body) => {
        return new Promise((resolve, reject) => {
        

          ApiUtils.setAxiosDefaultHeaders(axios);

          axios.put(process.env.REACT_APP_API_URL + `/Devices/Update`, body)
            .then((response) => {
              let res =ApiUtils.handleApiResponse(response);
              resolve(res);
            })
            .catch((error) => {
              reject({
                status: false,
                code: -1,
                message: error.message,
              });
            });
        });
      },
    },
    deleteDevice: {
      default: {
        data: {
          id: "",
        },
        status: true,
        code: 0,
        message: "",
      },
      call: async (body) => {
        return new Promise((resolve, reject) => {
          
          ApiUtils.setAxiosDefaultHeaders(axios);

          axios.delete(process.env.REACT_APP_API_URL + `/Devices/Delete/${body}`)
            .then((response) => {
              let res =ApiUtils.handleApiResponse(response);
              resolve(res);
            })
            .catch((error) => {
              reject({
                status: false,
                code: -1,
                message: error.message,
              });
            });
        });
      },
    },

  },

  groupApi: {
    groupList: {
      default: {
        data: [],
        status: true,
        code: 0,
        message: "",
      },
      call: async () => {
        return new Promise((resolve, reject) => {
       
          ApiUtils.setAxiosDefaultHeaders(axios);

          axios.get(process.env.REACT_APP_API_URL + `/Groups/List`)
            .then((response) => {
              let res =ApiUtils.handleApiResponse(response);
              resolve(res);
            })
            .catch((error) => {
              reject({
                status: false,
                code: -1,
                message: error.message,
              });
            });
        });
      },
    },
    getGroupById: {
      default: {
        data: { id: "" },
        status: true,
        code: 0,
        message: "",
      },
      call: async (body) => {
        return new Promise((resolve, reject) => {
      
          ApiUtils.setAxiosDefaultHeaders(axios);

          axios
            .get(process.env.REACT_APP_API_URL + `/Groups/GetById/${body}`)
            .then((response) => {
              let res =ApiUtils.handleApiResponse(response);
              resolve(res);
            })
            .catch((error) => {
              reject({
                status: false,
                code: -1,
                message: error.message,
              });
            });
        });
      },

    },
    addGroup: {
      default: {
        data: {},
        status: true,
        code: 0,
        message: "",
      },
      call: async (body) => {
        return new Promise((resolve, reject) => {

       

          ApiUtils.setAxiosDefaultHeaders(axios);

          axios.post(process.env.REACT_APP_API_URL + `/Groups/Add`, body).then((response) => {
            let res =ApiUtils.handleApiResponse(response);
    
            resolve(res);
          }).catch((error) => {

            reject({
              status: false,
              code: -1,
              message: error.response.data.message,
            });
          });
        });
      },
    },
    updateGroup: {
      default: {
        data: {},
        status: true,
        code: 0,
        message: "",
      },


      call: async (body) => {
        return new Promise((resolve, reject) => {
     

          ApiUtils.setAxiosDefaultHeaders(axios);

          axios
            .put(process.env.REACT_APP_API_URL + `/Groups/Update`, body)
            .then((response) => {
              let res =ApiUtils.handleApiResponse(response);
              resolve(res);
            })
            .catch((error) => {
              reject({
                status: false,
                code: -1,
                message: error.message,
              });
            });
        });
      },
    },
    deleteGroup: {
      default: {
        data: {},
        status: true,
        code: 0,
        message: "",
      },
      call: async (body) => {
        return new Promise((resolve, reject) => {
        
          ApiUtils.setAxiosDefaultHeaders(axios);

          axios.delete(process.env.REACT_APP_API_URL + `/Groups/Delete/${body}`)
            .then((response) => {
              let res =ApiUtils.handleApiResponse(response);
              resolve(res);
            })
            .catch((error) => {
              reject({
                status: false,
                code: -1,
                message: error.message,
              });
            });
        });
      },
    },
  },

  employeesApi: {

    employeesList: {
      default: {
        data: [],
        status: true,
        code: 0,
        message: "",
      },
      call: async () => {
        return new Promise((resolve, reject) => {
     
          ApiUtils.setAxiosDefaultHeaders(axios);

          axios.get(process.env.REACT_APP_API_URL + `/Employees/List?loadPhotos=false`)
            .then((response) => {
              let res =ApiUtils.handleApiResponse(response);
              resolve(res);
            })
            .catch((error) => {
              reject({
                status: false,
                code: -1,
                message: error.message,
              });
            });
        });
      },
    },
    GetPhoto: {
      default: {
        data: [],
        status: true,
        code: 0,
        message: "",
      },
      call: async (id) => {
        return new Promise((resolve, reject) => {
     
          ApiUtils.setAxiosDefaultHeaders(axios);

          axios.get(process.env.REACT_APP_API_URL + `/Employees/GetPhoto/${id}`)
            .then((response) => {
              let res =ApiUtils.handleApiResponse(response);
              resolve(res);
            })
            .catch((error) => {
              reject({
                status: false,
                code: -1,
                message: error.message,
              });
            });
        });
      },
    },
    
    getPage:{
      default: {
        data: [],
        status: true,
        code: 0,
        message: "",
      },
      call: async (pageNo,size,sortColumn,order) => {
        return new Promise((resolve, reject) => {
      
          ApiUtils.setAxiosDefaultHeaders(axios);
      
          axios.get(process.env.REACT_APP_API_URL + `/Employees/GetPage?pageNo=${pageNo}&size=${size}&sortColumn=${sortColumn || "FullName"}&order=${order}` )
            .then((response) => {
              let res =ApiUtils.handleApiResponse(response);
              resolve(res);
            })
            .catch((error) => {
              reject({
                status: false,
                code: -1,
                message: error.message,
              });
            });
        });
      },
    },

    getEmployeesById: {
      default: {
        data: { id: "" },
        status: true,
        code: 0,
        message: "",
      },
      call: async (body) => {
        return new Promise((resolve, reject) => {
        
          ApiUtils.setAxiosDefaultHeaders(axios);

          axios
            .get(process.env.REACT_APP_API_URL + `/Employees/GetById/${body}`)
            .then((response) => {
              let res =ApiUtils.handleApiResponse(response);
              resolve(res);
            })
            .catch((error) => {
              reject({
                status: false,
                code: -1,
                message: error.message,
              });
            });
        });
      },

    },
    import: {
      default: {
        data: {},
        status: true,
        code: 0,
        message: "",
      },
      call: async (body) => {
        return new Promise((resolve, reject) => {

          ApiUtils.setAxiosDefaultHeaders(axios);

          axios.post(process.env.REACT_APP_API_URL + `/Employees/Import`, body).then((response) => {
            let res =ApiUtils.handleApiResponse(response);
        
            resolve(res);
          }).catch((error) => {

            reject({
              status: false,
              code: -1,
              message: error.response.data.message,
            });
          });
        });
      },
    },    
    importLargeFile: {
      default: {
        data: {},
        status: true,
        code: 0,
        message: "",
      },
      call: async (body) => {
        return new Promise((resolve, reject) => {

          ApiUtils.setAxiosDefaultHeaders(axios);

          axios.post(process.env.REACT_APP_API_URL + `/Employees/ImportLargeFile`, body).then((response) => {
            let res =ApiUtils.handleApiResponse(response);
        
            resolve(res);
          }).catch((error) => {

            reject({
              status: false,
              code: -1,
              message: error.response.data.message,
            });
          });
        });
      },
    },
    export:{
      call: async (body) => {
        return new Promise((resolve, reject) => {
          ApiUtils.setAxiosDefaultHeaders(axios);
          axios.post(process.env.REACT_APP_API_URL + `/Employees/Export`, body, {responseType: 'blob'}).then((response) => {
            let res =ApiUtils.handleApiResponse(response);
            resolve(res);
          }).catch((error) => {
            reject({
              status: false,
              code: -1,
              message: error.response.data.message,
            });
          });
        });
      },
    },
    addEmployees: {
      default: {
        data: {},
        status: true,
        code: 0,
        message: "",
      },
      call: async (body) => {
   
        return new Promise((resolve, reject) => {

    
          ApiUtils.setAxiosDefaultHeaders(axios);

          axios.post(process.env.REACT_APP_API_URL + `/Employees/Add`, body).then((response) => {
            let res =ApiUtils.handleApiResponse(response);
        
            resolve(res);
          }).catch((error) => {

            reject({
              status: false,
              code: -1,
              message: error.response.data.message,
            });
          });
        });
      },
    },
    updateEmployees: {
      default: {
        data: {},
        status: true,
        code: 0,
        message: "",
      },


      call: async (body) => {

        return new Promise((resolve, reject) => {
      
          ApiUtils.setAxiosDefaultHeaders(axios);

          axios.put(process.env.REACT_APP_API_URL + `/Employees/Update`, body)
            .then((response) => {
              let res =ApiUtils.handleApiResponse(response);
              resolve(res);
            })
            .catch((error) => {
              reject({
                status: false,
                code: -1,
                message: error.message,
              });
            });
        });
      },
    },
    deleteEmployees: {
      default: {
        data: {},
        status: true,
        code: 0,
        message: "",
      },
      call: async (id) => {
        return new Promise((resolve, reject) => {
     
          ApiUtils.setAxiosDefaultHeaders(axios);

          axios.delete(process.env.REACT_APP_API_URL + `/Employees/Delete/${id}`)
            .then((response) => {
              let res =ApiUtils.handleApiResponse(response);
              resolve(res);
            })
            .catch((error) => {
              reject({
                status: false,
                code: -1,
                message: error.message,
              });
            });
        });
      },
    },
  },

  accessLevelsApi: {
    accessLevelList: {
      default: {
        data: [],
        status: true,
        code: 0,
        message: "",
      },
      call: async () => {
        return new Promise((resolve, reject) => {
        
          ApiUtils.setAxiosDefaultHeaders(axios);

          axios.get(process.env.REACT_APP_API_URL + `/AccessLevels/List`)
            .then((response) => {
              let res =ApiUtils.handleApiResponse(response);
              resolve(res);
            })
            .catch((error) => {
              reject({
                status: false,
                code: -1,
                message: error.message,
              });
            });
        });
      },
    },
    getAccessLevelById: {
      default: {
        data: { id: "" },
        status: true,
        code: 0,
        message: "",
      },
      call: async (body) => {
        return new Promise((resolve, reject) => {
        
          ApiUtils.setAxiosDefaultHeaders(axios);

          axios
            .get(process.env.REACT_APP_API_URL + `/AccessLevels/GetById/${body}`)
            .then((response) => {
              let res =ApiUtils.handleApiResponse(response);
              resolve(res);
            })
            .catch((error) => {
              reject({
                status: false,
                code: -1,
                message: error.message,
              });
            });
        });
      },

    },
    addAccessLevel: {
      call: async (body) => {
        return new Promise((resolve, reject) => {

    
          ApiUtils.setAxiosDefaultHeaders(axios);

          axios.post(process.env.REACT_APP_API_URL + `/AccessLevels/Add`, body).then((response) => {
            let res =ApiUtils.handleApiResponse(response);

            resolve(res);
          }).catch((error) => {

            reject({
              status: false,
              code: -1,
              message: error.response.data.message,
            });
          });
        });
      },
    },
    updateAccessLevel: {
      default: {
        data: {},
        status: true,
        code: 0,
        message: "",
      },


      call: async (body) => {
        return new Promise((resolve, reject) => {

          ApiUtils.setAxiosDefaultHeaders(axios);

          axios.put(process.env.REACT_APP_API_URL + `/AccessLevels/Update`, body)
            .then((response) => {
              let res =ApiUtils.handleApiResponse(response);
              resolve(res);
            })
            .catch((error) => {
              reject({
                status: false,
                code: -1,
                message: error.message,
              });
            });
        });
      },
    },
    deleteAccessLevel: {
      default: {
        data: {},
        status: true,
        code: 0,
        message: "",
      },
      call: async (body) => {
        return new Promise((resolve, reject) => {
         
          ApiUtils.setAxiosDefaultHeaders(axios);

          axios.delete(process.env.REACT_APP_API_URL + `/AccessLevels/Delete/${body}`)
            .then((response) => {
              let res =ApiUtils.handleApiResponse(response);
              resolve(res);
            })
            .catch((error) => {
              reject({
                status: false,
                code: -1,
                message: error.message,
              });
            });
        });
      },
    },
  },
  userApi: {
    userList: {
      default: {
        data: [],
        status: true,
        code: 0,
        message: "",
      },
      call: async () => {
        return new Promise((resolve, reject) => {
          
          ApiUtils.setAxiosDefaultHeaders(axios);

          axios.get(process.env.REACT_APP_API_URL + `/Users/List`)
            .then((response) => {
              let res =ApiUtils.handleApiResponse(response);
              resolve(res);
            })
            .catch((error) => {
              reject({
                status: false,
                code: -1,
                message: error.message,
              });
            });
        });
      },
    },
    getUserById: {
      default: {
        data: { id: "" },
        status: true,
        code: 0,
        message: "",
      },
      call: async (body) => {
        return new Promise((resolve, reject) => {
    

          ApiUtils.setAxiosDefaultHeaders(axios);

          axios
            .get(process.env.REACT_APP_API_URL + `/Users/GetById/${body}`)
            .then((response) => {
              let res =ApiUtils.handleApiResponse(response);
              resolve(res);
            })
            .catch((error) => {
              reject({
                status: false,
                code: -1,
                message: error.message,
              });
            });
        });
      },

    },
    addUser: {
      default: {
        data: {},
        status: true,
        code: 0,
        message: "",
      },
      call: async (body) => {
        return new Promise((resolve, reject) => {
        

          ApiUtils.setAxiosDefaultHeaders(axios);

          axios.post(process.env.REACT_APP_API_URL + `/Users/Add`, body).then((response) => {
            let res =ApiUtils.handleApiResponse(response);
       
            resolve(res);
          }).catch((error) => {
            
            reject({
              status: false,
              code: -1,
              message: error.response.data.message,
            });
          });
        });
      },
    },
    updateUser: {
      default: {
        data: {
          role: "",
          userEmail: "",
          userName: "",
          id: "",
        },
        status: true,
        code: 0,
        message: "",
      },


      call: async (body) => {
        return new Promise((resolve, reject) => {
        

          ApiUtils.setAxiosDefaultHeaders(axios);

          axios.put(process.env.REACT_APP_API_URL + `/Users/Update`, body)
            .then((response) => {
              let res =ApiUtils.handleApiResponse(response);
              resolve(res);
            })
            .catch((error) => {
              reject({
                status: false,
                code: -1,
                message: error.message,
              });
            });
        });
      },
    },
    deleteUser: {
      default: {
        data: {
          id: "",
        },
        status: true,
        code: 0,
        message: "",
      },
      call: async (body) => {
        return new Promise((resolve, reject) => {
          
          ApiUtils.setAxiosDefaultHeaders(axios);

          axios.delete(process.env.REACT_APP_API_URL + `/Users/Delete/${body}`)
            .then((response) => {
              let res =ApiUtils.handleApiResponse(response);
              resolve(res);
            })
            .catch((error) => {
              reject({
                status: false,
                code: -1,
                message: error.message,
              });
            });
        });
      },
    },
  },
};

export default ManagementApi;