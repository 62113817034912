import { useFormik } from 'formik';
import React from 'react'
import { getInitialValues } from '../../../extensions/FormikExtension';
import { userSchema, userEmail, userName, role } from '../../../validationSchema/UserSchema';
import ManagementApi from './../../../Api/ManagementApi';
import { useEffect } from 'react';
import { useState } from 'react';
import { ToastTemplate, ToastType } from '../../../notifications/ToastExecutor';
import LoadingScreen from '../../../Component/LoadingScreen/LoadingScreen';

export default function UserModal(props) {
  const[loading,setLoading]=useState(false)
  const formik = useFormik({
    initialValues: getInitialValues([userEmail,userName, role]),
    validationSchema: userSchema,
    onSubmit: async () => {
      if(props.mode === "edit"){
        editUser()
      }else{
        addUser()
      }
      formik.resetForm()
    },
  });
  function collectData(e){
    formik.setFieldValue([e.target.name], e.target.value)
  }
  async function addUser(){
    let body = {
     name: formik.getFieldProps(userName.Name).value,
     email: formik.getFieldProps(userEmail.Name).value,
     role:  formik.getFieldProps(role.Name).value,
     }
     setLoading(true)
     try {
       let res = await ManagementApi.userApi.addUser.call(body);
     if(res.status){
       new ToastTemplate(ToastType.Success,res.message).execute()
           setLoading(false)
           props.refreshUserList()
         }else{
           new ToastTemplate(ToastType.Error,res.message).execute()
           setLoading(false)
         }
     }
     catch(err) {
       new ToastTemplate(ToastType.Error,err.message).execute()
      setLoading(false)
     }
   }
   
   function handleUser(data){
    formik.setFieldValue(userName.Name,data.name || "")
    formik.setFieldValue(userEmail.Name,data.email || "")
    formik.setFieldValue(role.Name,data.role || "")
  }

  useEffect(()=>{
    if(props.mode === "edit"){
      handleUser(props?.data)
    }
  },[props])

  async function editUser(){
    try{
      let body ={
        name: formik.getFieldProps(userName.Name).value,
        email: formik.getFieldProps(userEmail.Name).value,
        role:  formik.getFieldProps(role.Name).value,
        id: props?.data?.id
      }
      setLoading(true)
      let res = await ManagementApi.userApi.updateUser.call(body);
      if(res.status){
      new ToastTemplate(ToastType.Success,res.message).execute()
      props.refreshUserList()
            setLoading(false)
          }else{
      new ToastTemplate(ToastType.Error,res.message).execute()
            setLoading(false)
          }
    } catch(err) {
      new ToastTemplate(ToastType.Error,err.message).execute()
      setLoading(false)
     }   
  }

  return (
   <>
        {
      loading?<LoadingScreen/>:
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header  border-0">
              <h1
                className="modal-title fs-5 title-add-madal"
                id="exampleModalLabel"
              >
                {
                  props.mode === "add"?"Add User":"Edit User" 
                }
              </h1>
              <button
                type="button"
                className="btn-close"
                style={{ "top": "18px",
                  "position": "absolute",
                  "right": "15px" }}
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={()=>{ }}
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={formik.handleSubmit} className='d-flex align-items-center flex-column'>
                <div className="field-group">
                  <label
                    htmlFor="userName"
                    className="d-block ModelNumber-label"
                  >
                    User Name
                  </label>
                  <input
                    {...formik.getFieldProps(userName.Name)}
                    type="text"
                    className={
                      formik.getFieldMeta(userName.Name).touched &&
                      formik.getFieldMeta(userName.Name).error != null
                        ? "form-control input-error error-margin Model-Number"
                        : "form-control Model-Number"
                    }
                    name=""
                    id={userName.Name}
                  />
                  {formik.getFieldMeta(userName.Name).touched &&
                  formik.getFieldMeta(userName.Name).error != null ? (
                    <div className="error">
                      {formik.getFieldMeta(userName.Name).error}
                    </div>
                  ) : null}
                </div>
                <div className="field-group">
                  <label
                    htmlFor="userEmail"
                    className="d-block ModelNumber-label"
                  >
                    User Email
                  </label>
                  <input
                    {...formik.getFieldProps(userEmail.Name)}
                    type="text"
                    className={
                      formik.getFieldMeta(userEmail.Name).touched &&
                      formik.getFieldMeta(userEmail.Name).error != null
                        ? "form-control input-error error-margin Model-Number"
                        : "form-control Model-Number"
                    }
                    name=""
                    id={userEmail.Name}
                  />
                  {formik.getFieldMeta(userEmail.Name).touched &&
                  formik.getFieldMeta(userEmail.Name).error != null ? (
                    <div className="error">
                      {formik.getFieldMeta(userEmail.Name).error}
                    </div>
                  ) : null}
                </div>
                <div className="field-group">
                  <label htmlFor="Role" className="d-block Zone-label">
                    Role
                  </label>
                  <select
                    onChange={(e)=>{formik.handleChange(collectData(e));}}                
                    className={
                      formik.getFieldMeta(role.Name).touched &&
                      formik.getFieldMeta(role.Name).error != null
                        ? "form-control input-error error-margin Model-Number"
                        : "form-control Model-Number"
                    }                   
                    name={role.Name}
                    id={role.Name}
                     {...formik.getFieldProps(role.Name)}
                  >
                    <option  value="">Select Role</option>
                    <option  value="3">Viewer</option>
                    <option  value="2">Operator</option>
                    <option  value="1">Administrator</option>
                  </select>
                  {formik.getFieldMeta(role.Name).touched &&
                  formik.getFieldMeta(role.Name).error != null ? (
                    <div className="error">
                      {formik.getFieldMeta(role.Name).error}
                    </div>
                  ) : null}
                </div>
                <div className="modal-footer border-0 d-flex justify-content-center">
                  <button
                    type="submit"
                    className={!(formik.dirty && formik.isValid )?"disabled-btn":"add-modal-btn"}
                    data-bs-dismiss="modal"
                    disabled={!(formik.dirty && formik.isValid)}
                  >
                  {
                    props.mode === "add"?"Add":"Apply changes" 
                  }
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>}
   </>
  )
}