import React, { useEffect, useState } from 'react';
import './Navbar.css'
import logo from '../../Assets/Technip_Energies_Logo.svg'
import { RxDashboard } from "react-icons/rx"
import { Link } from 'react-router-dom'
import {RiArrowDropDownLine} from "react-icons/ri"
import {HiOutlineClipboardList} from "react-icons/hi"
import {CiSaveDown1,CiSaveUp1} from "react-icons/ci"
import {BsBriefcase} from "react-icons/bs"
import {LoginApi, requireOperatorRole, requireAdminRole}  from "../../Api/LoginApi"
import DashboardApi from '../../Api/DashboardApi';


function Navbar() {   
    const [appLink, setAppLink] = useState('');
    function logout(){
        LoginApi.navigateToLoginPage()
    }
 
    useEffect(() => {
        const getMobileAppLink = async () => {
            DashboardApi.getFigures
            .call()
            .then((res) => {
            if (res.status) {
                setAppLink(res.data);
            }})
            .catch((error) => {
              //new ToastTemplate(ToastType.Error, error.message).execute();
            })
        };

        getMobileAppLink();
      }, []);

      const isAndroid = navigator.userAgent.toLowerCase().includes('android');

    return (
        <>
            <div className='top-navbar'>
                <div className='container-fluid'>
                    <div className='row d-flex align-items-center justify-content-between'>
                        <div className=' col-3'>
                            <img src={logo} className="nav-logo" alt="" />
                        </div>
                        <div className=' col-9 d-flex justify-content-end '>
                            <div className='avatar-group'>
                                <div className="dropdown">
                                    <button className="btn border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span className='text-white px-2'>Hello, {LoginApi.getFriendlyName()}</span>
                                        <RiArrowDropDownLine className='fs-1 text-light' />
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <button className='logout-btn' onClick={()=>{logout()}}>logout</button>
                                        </li>                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='bottom-navbar'>
                <nav className="navbar navbar-expand-lg bg-body-tertiary">
                    <div className="container-fluid rwd-m ms-5">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item  me-5">
                                    <Link className="nav-link d-flex align-items-center" aria-current="page" to="home"><RxDashboard className='nav-icon'/>Home</Link>
                                </li>
                                <li className="nav-item  me-5">
                                    <Link className="nav-link d-flex align-items-center" aria-current="page" to="Reports"><HiOutlineClipboardList className='nav-icon'/>Reports</Link>
                                </li>
                                {requireOperatorRole() && ( // Render the following links only if userRole is "admin"
                                    <>
                                    <li className="nav-item  me-5">
                                        <Link className="nav-link d-flex align-items-center" aria-current="page" to="Import"><CiSaveDown1 className='nav-icon'/>Import</Link>
                                    </li>
                                    {/* <li className="nav-item  me-5 disabled">
                                        <Link className="nav-link d-flex align-items-center" aria-current="page" to="Export"><CiSaveUp1 className='nav-icon'/>Export</Link>
                                    </li> */}
                                    {requireAdminRole() && ( // Render the following links only if userRole is "admin"
                                    <>
                                        <li className="nav-item  me-5">
                                            <Link className="nav-link d-flex align-items-center" aria-current="page" to="Management"><BsBriefcase className='nav-icon'/>Management</Link>
                                        </li>
                                    </>                                
                                    )}    
                                </>                                
                                )}                               
                            </ul>
                            {isAndroid && appLink && (
                                <div className="text-center">
                                <a href={appLink} className="android-app-link">
                                    Download Android App
                                </a>
                                </div>
                            )}
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
}

export default Navbar
