import React, { useEffect, useState, useRef } from 'react';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import $ from 'jquery';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import LoginApi from '../../../Api/LoginApi';
import moment from "moment";
import FilterModal from './../modal/FilterModal';
import LoadingScreen from './../../../Component/LoadingScreen/LoadingScreen';

export default function TransactionTable() {
  const [filterData, setFilterData] = useState({});
  const table = useRef(null);
  const [loading, setLoading] = useState(false);
  const dataTableSearch = useState({});

  useEffect(() => {
    if (table.current) {
      table.current.destroy();
    }
    table.current = $('#table').DataTable({
      responsive: true,
      processing: true,
      serverSide: true,
      filter: true,
      ajax: {
        url: process.env.REACT_APP_API_URL + '/Reports/GetEmployeeTransactionsPage',
        type: 'POST',
        beforeSend: function (xhr) {
          if (Object.keys(filterData).length === 0) {
            xhr.abort(); // Abort the request if filterData is empty
          } else {
            xhr.setRequestHeader(
              "Authorization",
              LoginApi.getToken()
            );
          }
      
        },
        data: function (d) {
          // Add additional parameters for paging, sorting, and searching
          d.orderBy = d.columns[d.order[0].column].data; // Column to order by
          d.orderDir = d.order[0].dir; // Ordering direction
          d.searchText = d.search.value; // Search text
          const columnSearch = d.columns
          .filter((column) => column.search.value && column.search.value.trim() !== "")
          .reduce((acc, column) => {
            acc[column.data] = column.search.value.trim();
            return acc;
          }, {});
          d.columnSearch = JSON.stringify(columnSearch);
          d.fromDate = filterData.From;
          d.toDate = filterData.To;
          d.zones = filterData.Zones;
          d.groups = filterData.Groups;
          dataTableSearch.current = columnSearch;
        },
        dataSrc: 'data', // Property name in the response JSON containing the data array
        error: function (xhr, error, thrown) {
          console.log('Server-Paging', error);
        },
      },
      language: {
        paginate: {
          previous: '<',
          next: '>',
        },
      },
      lengthMenu: [
        [10, 15, 20, 30],
        [10, 15, 20, 30],
      ],
      order: [[6, 'desc']],
      pageLength: 10,
      columns: [
        {
          title: `UID`,
          data: "uid",
          searchable: true,
          orderable: true,
          createdCell: function (td, cellData, rowData, row, col, tr) {
            $(td).addClass("col-edit");
            $(rowData).addClass("te-class");
          },
        },
        {
          title: `Name`,
          data: "name",
          searchable: true,
          orderable: true,
          createdCell: function (td, cellData, rowData, row, col, tr) {
            $(td).addClass("col-edit");
            $(rowData).addClass("te-class");
          },
        },
        {
          title: `Position`,
          data: "position",
          searchable: true,
          orderable: true,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass("col-edit");
          },
        },
        {
          title: `Group`,
          data: "group",
          searchable: true,
          orderable: true,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass("col-edit");
          },
        },
        {
          title: `Zone`,
          data: "zone",
          searchable: true,
          orderable: true,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass("col-edit");
          },
        },
        {
          title: `Action`,
          data: "action",
          searchable: true,
          orderable: true,
          createdCell: function (td, cellData, rowData, row, col) {
            let actionText = td.textContent.toUpperCase();
            let failedActionText = actionText.endsWith('ERROR')?'FAILED' : actionText.endsWith('UNKOWN')?'UNKOWN' : '';
            if (actionText.startsWith('CHECKOUT')) {
              td.innerHTML = "<p class='checkOut mb-0'" + (failedActionText.length > 0? " style='color:hsl(56, 61%, 28%) !important;'":"") + ">" + (failedActionText.length > 0?failedActionText:'Check Out') + '</p>';
            } else if (actionText.startsWith('CHECKIN')) {
              td.innerHTML = "<p class='checkIn mb-0'" + (failedActionText.length > 0? " style='color:hsl(56, 61%, 28%) !important;'":"") + ">" + (failedActionText.length > 0?failedActionText:'Check In') + '</p>';
            }

            $(td).addClass("col-data-edit");
          },
        },
        {
          title: `Time`,
          data: "time",
          searchable: false,
          orderable: true,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass("col-edit");
          },
          render: function (data) {
            return moment(data).format("YYYY-MM-DD hh:mm A");
          },
          type: "date",
          orderDataType: "dom-date",
        },
      ],
      dom:
      "<'row  '<'col-sm-12 col-md-6 d-flex mt-3'f><'col-sm-6  flex-row-reverse col-md-6 d-flex flex-row-reverse mb-5'B>>" +
      "t<'row  e_table'<'col-sm-12 col-md-6 d-flex align-items-end justify-content-end 'p><'col-sm-6  flex-row-reverse col-md-6 d-flex flex-row-reverse   mt-3'l>>",
      buttons: [
        {
          extend: 'excelHtml5',
          text: '<i class="fa-solid fa-file-excel"></i>',
          className:"btn-report fs-4",
          action: function (e, dt, button, config) {
            setLoading(true)
            const searchText = dt.search();
            const columns = dt.settings().init().columns;
            const columnSearch = dataTableSearch.current;
            const columnNames = columns.map((column) => column.data);
            const fromDate = filterData.From;
            const toDate = filterData.To;
            const zones = filterData.Zones;
            const groups = filterData.Groups;

            // Make a request to your ASP.NET Core Web API to export the data
            fetch(process.env.REACT_APP_API_URL + `/Reports/TransactionsExport`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': LoginApi.getToken()
              },
              body: JSON.stringify({
                searchText,
                columnNames,
                columnSearch,
                fromDate,
                toDate,
                zones,
                groups
              })
            })
              .then((response) => response.blob())
              .then((blob) => {
                // Create a download link for the Excel file
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'Transactions.xlsx';

                // Trigger the download
                link.click();

                // Clean up the temporary URL
                setTimeout(function () {
                  URL.revokeObjectURL(url);
                }, 100);
              })
              .catch((error) => {
                console.error(error);
              });
            setLoading(false)
          }
        },
        {
          text: '<i class="fa-solid fa-rotate-right fs-4"></i>',
          className: 're-btn ',
          action: function (e, dt, node, config) {
            dt.ajax.reload()
          },
        },
      ],
      columnDefs: [
        {
          defaultContent: '-',
          targets: ['_all'],
          className: 'dt-body-left',
        },
        { orderable: false, targets: [0, 1, 2, 3, 4, 5] },
      ],
      initComplete: function () {
        $('#table thead tr')
          .removeClass('col-edit')
          .addClass('filters')
          .appendTo('#table thead');
    
        var api = this.api();
        // For each column
        api.columns()
          .eq(0)
          .each(function (colIdx) {
            var column = api.settings().init().columns[colIdx];
            var cell = $('.filters th').eq($(api.column(colIdx).header()).index());
            var title = column.title;
            if (!column.searchable) {  
              $(cell).html(`
                <div style="display: flex; align-items: center;">
                  <span class="col-edit">${title}</span>
                </div>
              `);
            } else{// If the column is searchable, add the search input      
              // Set the header cell to contain the input element
              $(cell).html(`
              <div style="display: flex; align-items: center;">
                <span class="col-edit">${title}</span>
                <img src="/search.svg" alt="search" style="cursor: pointer; margin-left: auto;" />
              </div>
              <div class="hide clear-input-container" >
                <input type="text" placeholder="search" class="clear-input" />
                <button class="clear-input-button">X</button>
              </div>
              `);
    
              // On clicking the search icon, toggle the visibility of the input element
              $('img', cell).on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
                $('.clear-input-container', cell).toggleClass('hide');
              });
    
              $('input', cell)
              .on('input', function (e) {
                const input = $(this);
                if (input.val() && !input.hasClass("clear-input--touched")) {
                  input.addClass("clear-input--touched");
                } else if (!input.val() && input.hasClass("clear-input--touched")) {
                  input.removeClass("clear-input--touched");
                }
              });
              $('button', cell)
              .on('click', function (e) {
                e.stopPropagation();
                const input = $(this).siblings(".clear-input");
                input.val('');
                input.removeClass("clear-input--touched");
                input.trigger('change');
                $('.clear-input-container', cell).toggleClass('hide');
              });
              // On every keypress in this input
              $('input', cell)
                  .off('keyup change')
                  .on('change', function (e) {
                      // Get the search value
                     $(this).attr('title', $(this).val());
                      api.columns(colIdx).search(this.value).draw();
                  })
                  .on('keydown', function (e) {
                    e.stopPropagation();
                  })
                  .on('keyup', function (e) {
                      e.stopPropagation();
                      $(this).trigger('change');
                  })
                  .on('click', function (e) {
                    e.preventDefault(); // Prevent the default behavior (sorting action)
                    e.stopPropagation(); // Stop event propagation to avoid unintended side effects
                  });
          }});
        },
    });
  });

  return (
    <>
      {
        loading ? <LoadingScreen /> : <></>
      }
      <div className='card-table'>
        <table id='table' className='display    row-border ' style={{ width: '100%', marginTop: '40px' }}></table>
      </div>
      <div className='modal fade' id='filterModal' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='staticBackdropLabel' aria-hidden='true'>
      <FilterModal f={setFilterData} />
      </div>
    </>
  );
}
