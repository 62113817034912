import { Field } from '../extensions/FormikExtension'
import * as Yup from "yup";
export var groupNameField = new Field({ Name: "groupNameField", InitialValue: "" })
export var groupAccessLevel = new Field({ Name: "groupAccessLevel", InitialValue:[]})


export const GroupSchema = Yup.object({
	groupNameField: Yup.string().required('Please Enter  group Name'),
    groupAccessLevel: Yup.array()//.min(1).required("Please select one of  group Access Levels"),
   
})