import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import user from "../../Assets/icon/User - Free people icons-2 2.svg";
import chartIcon from "../../Assets/icon/Performance - Free business icons-2 1.svg";
import lineIcon from "../../Assets/icon/Path 2.svg";
import Bar from "./charts/BarCharts";
import Pie from "./charts/Pie";
import clockIcon from "../../Assets/icon/Layer 9.svg";
import EmployeeTranactionTable from "./EmployeeTransactionTable";
import DashboardApi from "../../Api/DashboardApi";
import utils from "../../utils/utils";
import { ToastTemplate, ToastType } from "./../../notifications/ToastExecutor";
function Home() {

  function formatFigure(figure) { return figure == null ? '_' : Math.round(figure); }

  const [figures, setFigures] = useState(DashboardApi.getFigures.default.data);
  const [resourcesDistributionData, setResourcesDistributionData] = useState(
    DashboardApi.ResourcesDistribution.default.data
  );
  const [accessPerDay, setAccessPerDay] = useState(
    DashboardApi.AccessPerDay.default.data
  );

  const refreshRate = 5000; // refreshRate in MS


  const updateFigures = utils.throttle(() => {
    {
      DashboardApi.getFigures
        .call()
        .then((res) => {
          if (res.status) {
            setFigures(res.data);
          } else {
            new ToastTemplate(ToastType.Error, res.message).execute();
          }
        })
        .catch((error) => {
          new ToastTemplate(ToastType.Error, error.message).execute();
        });
    }

    {
      DashboardApi.ResourcesDistribution.call()
        .then((res) => {
          if (res.status) {
            setResourcesDistributionData(res.data);
          } else {
           new ToastTemplate(ToastType.Error, res.message).execute();         
          }
        })
        .catch((error) => {
          new ToastTemplate(ToastType.Error, error.message).execute();
        });
    }

    {
      DashboardApi.AccessPerDay.call()
        .then((res) => {
          if (res.status) {
            setAccessPerDay(res.data);
          } else {
            new ToastTemplate(ToastType.Error, res.message).execute();
          }
        })
        .catch((error) => {
          new ToastTemplate(ToastType.Error, error.message).execute();
        });
    }
  }, refreshRate);

  useEffect(() => {
    const interval = setInterval(updateFigures, refreshRate);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="home">
          <div className="home-title">
            <h1>Dashboard</h1>
          </div>
          <div className="card-container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="card-1">
                  <div className="card-content">
                    <h3>Active Employee Count </h3>
                    <p>{figures.numberOfEmployees}</p>
                  </div>
                  <img src={user} alt="" />
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card-2">
                  <div className="card-content">
                    <h3>Time Spent (Last 24 hrs)</h3>
                    <p>{formatFigure(figures.timeSpent)}</p>
                  </div>
                  <img src={chartIcon} alt="" />
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card-3">
                  <div className="card-content">
                    <h3>Total Zones Count</h3>
                    <p>{formatFigure(figures.numberOfZones)}</p>
                  </div>
                  <img src={lineIcon} alt="" style={{ width: "50%" }} />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-8 col-md-6 my-2">
              <div className="chart-card border-0">
                <Bar AccessPerDayData={accessPerDay} />

                <h1 className="barChart-title">Access Per Day</h1>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 my-2">
              <div className="pie-card">
                <h1 className="PieChart-title">Resources Distribution</h1>
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <Pie resourcesDistributionData={resourcesDistributionData} />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card-table ">
                <EmployeeTranactionTable refreshRate={refreshRate} />
                <p className="table-notfi">
                  <img src={clockIcon} alt="" /> Last 100 Transactions
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
