import { Field, getInitialValues } from '../extensions/FormikExtension'
import * as Yup from "yup";
export var fromDate = new Field({ Name: "fromDate", InitialValue: "" })
export var toDate = new Field({ Name: "toDate", InitialValue: "" })
export var zoneId = new Field({ Name: "zoneId", InitialValue: []})
export var groupId = new Field({ Name: "groupId", InitialValue: [] })



export const reportSchema = Yup.object({
    fromDate: Yup.string().required("Please Enter Start Date"),  
    toDate: Yup.string().required("Please Enter End Date"),
     zoneId:  Yup.array().min(1).required("Please select a zone "),
    groupId:  Yup.array().min(1).required("Please select a group"),        
    })

