import React, { useState } from 'react';
import { LoginApi } from '../../Api/LoginApi';
import { useFormik } from 'formik'
import { getInitialValues } from '../../extensions/FormikExtension'
import { resetPasswordSchema, userPassword, confirmPassword} from './../../validationSchema/LoginSchema';
import { ToastTemplate, ToastType } from './../../notifications/ToastExecutor';
import './login.css';
import { useNavigate, useLocation } from 'react-router-dom';


const NewPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token'); // Retrieve the token from the URL query params

  function handleFormSubmit() {
    setIsLoading(true);

      // Call the API to initiate the password reset process
      LoginApi.newPassword.call(token, formik.getFieldMeta(userPassword.Name).value).then((res) => {
        if (res.status) {
			new ToastTemplate(ToastType.Success, "Password changed successfully!").execute()
        // Success: Redirect to a confirmation page or display a success message
        navigate('/login')
      } else {
        // Error: Display an error message based on the response
        new ToastTemplate(ToastType.Error, res.message).execute()
      }
      setIsLoading(false);
    })
    .catch((error) => {
      new ToastTemplate(ToastType.Error, error.response.data.message).execute()
      setIsLoading(false)
  })
}

const formik = useFormik({
  initialValues: getInitialValues([userPassword, confirmPassword]),
  validationSchema: resetPasswordSchema,
  onSubmit: async () => {
    handleFormSubmit()
  }
})

  return (
    <div className='login'>
	<div className='row'>
		<div className='left-part col-lg-6 col-md-12 vh-100 d-flex align-items-center justify-content-center '>
		</div>
		<div className='right-part col-lg-6 col-md-12'>

			<div className='login-title'>
			<img src="/InnovAxess_Logo.svg" height="50px" alt="Product Logo" className="company-logo" />
			</div>

			<form
				onSubmit={(e) => {
					e.preventDefault();
					formik.handleSubmit();
				}} className='login-form'>
				<h3>Reset Password</h3>
				<div className='field-form-group '>
					<label className='form-label' htmlFor="" >New Password</label>
					<div className='input-box'>
						<input
							type="password"
							className={
								formik.getFieldMeta(userPassword.Name).touched &&
									formik.getFieldMeta(userPassword.Name).error != null
									? "form-control input-error error-margin"
									: "form-control field-password"
							}
							name="password" id={userPassword.Name} {...formik.getFieldProps(userPassword.Name)} />				
					</div>
				</div>
				{formik.getFieldMeta(userPassword.Name).touched &&
					formik.getFieldMeta(userPassword.Name).error != null ? (
					<div className="error">
						{formik.getFieldMeta(userPassword.Name).error}
					</div>
				) : null}
				
				<div className='field-form-group '>
					<label className='form-label' htmlFor="" >Confirm Password</label>
					<div className='input-box'>
						<input
							type="password"
							className={
								formik.getFieldMeta(confirmPassword.Name).touched &&
									formik.getFieldMeta(confirmPassword.Name).error != null
									? "form-control input-error error-margin"
									: "form-control field-password"
							}
							name="confirmPassword" id={confirmPassword.Name} {...formik.getFieldProps(confirmPassword.Name)} />				
					</div>
				</div>
				{formik.getFieldMeta(confirmPassword.Name).touched &&
					formik.getFieldMeta(confirmPassword.Name).error != null ? (
					<div className="error">
						{formik.getFieldMeta(confirmPassword.Name).error}
					</div>
				) : null}
				<button
					disabled={
						!(formik.dirty && formik.isValid) && !(formik.values.comfirmPassword && formik.values.userPassword)
					}
					className='btn login-btn' type='submit'>Change Password</button>
			</form>
			<div className="powered-by-container">
				<div className="powered-by-text">Powered By</div>
				<img src="/IOasis_Logo.svg" alt="Company Logo" className="company-logo" />
			</div>
		</div>
	</div>
</div>
  );
};

export default NewPassword;
