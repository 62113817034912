import { useFormik } from 'formik';
import React from 'react'
import { getInitialValues } from '../../../extensions/FormikExtension';
import { DeviceSchema, HWID, deviceName, modelNumber, zone, extras } from '../../../validationSchema/DeviceSchema';
import { useMemo } from 'react';
import ManagementApi from './../../../Api/ManagementApi';
import { useEffect } from 'react';
import { useState } from 'react';
import { ToastTemplate, ToastType } from '../../../notifications/ToastExecutor';
import LoadingScreen from '../../../Component/LoadingScreen/LoadingScreen';

export default function DeviceModal(props) {
  const[loading,setLoading]=useState(false)
  const [zoneList,setZoneList] = useState(ManagementApi.zoneApi.zoneList.default.data)
  const formik = useFormik({
    initialValues: getInitialValues([deviceName, modelNumber, HWID, zone, extras]),
    validationSchema: DeviceSchema,
    onSubmit: async () => {
      if(props.mode === "edit"){
        editDevice()
      }
      formik.resetForm()
    },
  });
  function collectData(e){
    formik.setFieldValue([e.target.name], e.target.value)
  }
   function handleDevice(data){
      formik.setFieldValue(deviceName.Name,data.deviceName || "")
      formik.setFieldValue(modelNumber.Name,data.serialNumber || "")
      formik.setFieldValue(HWID.Name,data.imei || "")
      formik.setFieldValue(zone.Name,data.zoneId || "")
      formik.setFieldValue(extras.Name,data.extras || "")
  }
  useEffect(()=>{
    if(props.mode === "edit"){
      handleDevice(props?.data)
    }
  },[props])
  async function editDevice(){
    try{
      let body ={
        deviceName: formik.getFieldProps(deviceName.Name).value,
        serialNumber: props?.data?.serialNumber,
        imei: props?.data?.imei,
        zoneId:formik.getFieldProps(zone.Name).value,
        androidID:props?.data?.androidID,
        id: props?.data?.id,
        extras: formik.getFieldProps(extras.Name).value
      }
      setLoading(true)
      let res = await ManagementApi.deviceApi.updateDevice.call(body);
      if(res.status){
      new ToastTemplate(ToastType.Success,res.message).execute()
      props.refreshDeviceList()
            setLoading(false)
          }else{
      new ToastTemplate(ToastType.Error,res.message).execute()
            setLoading(false)
          }
    } catch(err) {
      new ToastTemplate(ToastType.Error,err.message).execute()
      setLoading(false)
     }

     formik.resetForm()
  }
  async function fillZones(){
    try{
      setLoading(true)
      let res = await ManagementApi.zoneApi.zoneList.call();
      if(res.status){
            setZoneList(res.data);
            setLoading(false)
              return res.data
          }else{
            new ToastTemplate(ToastType.Error,res.message).execute()
            setLoading(false)
          }
    } catch(err) {
      new ToastTemplate(ToastType.Error,err.message).execute()
       setLoading(false)
      }
    }
    const MemoisedData = useMemo(() => {
      fillZones()
    }, []);
 
  return (
   <>
        {
      loading?<LoadingScreen/>:
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header  border-0">
              <h1
                className="modal-title fs-5 title-add-madal"
                id="exampleModalLabel"
              >
                {
                    props.mode === "add"?"Add Device":"Edit Device" 
                  }
              </h1>
              <button
                type="button"
                className="btn-close"
                style={{ "top": "18px",
                  "position": "absolute",
                  "right": "15px" }}
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={()=>{}}
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={formik.handleSubmit} className='d-flex align-items-center flex-column'>
              <div className="field-group">
                  <label
                    htmlFor="deviceName"
                    className="d-block ModelNumber-label"
                  >
                    Device Name
                  </label>
                  <input
                    {...formik.getFieldProps(deviceName.Name)}
                    type="text"
                    className={
                      formik.getFieldMeta(deviceName.Name).touched &&
                      formik.getFieldMeta(deviceName.Name).error != null
                        ? "form-control input-error error-margin Model-Number"
                        : "form-control Model-Number"
                    }
                    name={deviceName.Name}
                    id={deviceName.Name}
                  />
                  {formik.getFieldMeta(deviceName.Name).touched &&
                  formik.getFieldMeta(deviceName.Name).error != null ? (
                    <div className="error">
                      {formik.getFieldMeta(deviceName.Name).error}
                    </div>
                  ) : null}
                </div>
                <div className="field-group">
                  <label htmlFor="zone" className="d-block Zone-label">
                    Zone
                  </label>
                  <select
                  onChange={(e)=>{formik.handleChange(collectData(e));}}
                    className={
                      formik.getFieldMeta(zone.Name).touched &&
                      formik.getFieldMeta(zone.Name).error != null
                        ? "form-control input-error error-margin Model-Number"
                        : "form-control Model-Number"
                    }
                    name={zone.Name}
                    id={zone.Name}
                     {...formik.getFieldProps(zone.Name)}
                  >
                    <option  value="">Select zone</option>
                    {
                      zoneList.map((item)=>{
                        return(
                            <option key={item.id} value={item.id}>{item.name}</option>
                        )
                      })
                    }
                  </select>
                  {formik.getFieldMeta(zone.Name).touched &&
                  formik.getFieldMeta(zone.Name).error != null ? (
                    <div className="error">
                      {formik.getFieldMeta(zone.Name).error}
                    </div>
                  ) : null}
                </div>
                <div className="field-group">
                  <label
                    htmlFor="extras"
                    className="d-block ModelNumber-label"
                  >
                    Extras
                  </label>
                  <textarea
                    {...formik.getFieldProps(extras.Name)}
                    type="text"
                    className={
                      formik.getFieldMeta(extras.Name).touched &&
                      formik.getFieldMeta(extras.Name).error != null
                        ? "form-control input-error error-margin Model-Number"
                        : "form-control Model-Number"
                    }
                    name={extras.Name}
                    id={extras.Name}
                    rows={4}
                    style={{ height: "100px" }}
                  />
                  {formik.getFieldMeta(extras.Name).touched &&
                  formik.getFieldMeta(extras.Name).error != null ? (
                    <div className="error">
                      {formik.getFieldMeta(extras.Name).error}
                    </div>
                  ) : null}
                </div>
                <div className="modal-footer border-0 d-flex justify-content-center">
                  <button
                    type="submit"
                    className={!(formik.dirty && formik.isValid )?"disabled-btn":"add-modal-btn"}
                    data-bs-dismiss="modal"
                    disabled={!(formik.dirty && formik.isValid)}                  >
                     {
                    props.mode === "add"?"Add":"Apply changes" 
                  }
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>}
   </>
  )
}