import React,{useState,useEffect} from 'react'
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $, { css } from "jquery";
import { createRoot } from "react-dom/client";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import deleteIcon from "../../../Assets/icon/Delete.svg";

export default function EmployeeAcessLevelsTable() {
    const [users, setUsers] = useState([
        {AccessLevelName: "AL1"},
        {AccessLevelName: "AL1"},
        {AccessLevelName: "AL1"},
        {AccessLevelName: "AL1"},
        {AccessLevelName: "AL1"},
      ]);


      
      useEffect(() => {
        $("#EmployeeAcessLevelsTable").DataTable({
          stateSave: false,
          bDestroy: true,
          
          language: {
            paginate: {
              previous: "<",
              next: ">",
            },
          },
        
          data: users,
    
          columns: [
          
            {
              title: `Access Level Name`,
              data: "AccessLevelName",
              createdCell: function (td, cellData, rowData, row, col) {
                $(td).addClass("col-edit");
              },
            },
           
         
            { title: `Action` },
          ],
          dom:
            "<'row  '<'col-sm-12 col-md-6 d-flex '><'col-sm-6  flex-row-reverse col-md-6 d-flex flex-row-reverse mb-5'>>" +
            "tp",
    
          columnDefs: [
            {
              target: -1,
              createdCell: (td, cellData, rowData, row, col) => {
                createRoot(td).render(
                  <div className="d-flex align-items-center justify-content-around">
                    <button
                      className="deleteBtn border-0 bg-transparent"
                        onClick={()=>{}}
                    >
                      <img src={deleteIcon} alt="" />
                    </button>
                
                  </div>
                );
              },
            },
    
            {
              defaultContent: "-",
              targets: ["_all"],
              className: "dt-body-left",
            },
             { orderable: false, targets: [1] }
          ],
        });
    
      
      }, []);

  return (
    <>
       <div className='card-table'>
                            
                            <table id="EmployeeAcessLevelsTable" className="display text-center   row-border " style={{ "width": "100%" }}>
                            </table>
        </div> 
    </>
  )
}
