import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import './login.css';
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { loginSchema, userEmail, userPassword } from './../../validationSchema/LoginSchema';
import { getInitialValues } from '../../extensions/FormikExtension'
import { LoginApi } from '../../Api/LoginApi';
import LoadingScreen from './../../Component/LoadingScreen/LoadingScreen';
import { ToastTemplate, ToastType } from './../../notifications/ToastExecutor';

function Login() {
    const [showpassword, setShowPassword] = useState(false)
    const toggleShowPassword = () => setShowPassword(!showpassword)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    function login() {
        let body = {
            userName: formik.getFieldMeta(userEmail.Name).value,
            password: formik.getFieldMeta(userPassword.Name).value
        }
        setLoading(true)        
        LoginApi.login.call(body.userName, body.password).then((res) => {
            if (res.status) {
                LoginApi.setFriendlyName(res.data.name)
                LoginApi.setToken(res.data.token)
                LoginApi.setUserRole(res.data.role)

                navigate('/home')
                setLoading(false)
            } else {
                setLoading(false)
                new ToastTemplate(ToastType.Error, res.message).execute()
                LoginApi.clearLoginCache()
            }
        }).catch((error) => {
            setLoading(false)
            new ToastTemplate(ToastType.Error, error.response.data.message).execute()
            LoginApi.clearLoginCache()
        })
    }   

    function rememberMe(){
        if(document.querySelector('.remember-me').checked){
            LoginApi.setUserData(
                JSON.stringify({
                    userEmail:formik.getFieldMeta(userEmail.Name).value,
                })
            )
        }else{
            LoginApi.setUserData("")
        }
    }

    function setUserData(){
        const userData = LoginApi.getUserData();
        let data = userData !==""? JSON.parse(userData):""
        formik.setFieldValue(userEmail.Name,data.userEmail)
    }

    const formik = useFormik({
        initialValues: getInitialValues([userEmail, userPassword]),
        validationSchema: loginSchema,
        onSubmit: async () => {
            login()
        }
    })
    
    useEffect(()=>{
        const userData = LoginApi.getUserData();
          if(userData=== null || userData=== ""){
            LoginApi.setUserData("")
            formik.resetForm()
            document.querySelector('.remember-me').checked = false
          }else{
            setUserData()
            document.querySelector('.remember-me').checked = true
          }
    },[])

    return (
        <>
            {
                loading ? <LoadingScreen /> :
                    <div className='login'>
                        <div className='row'>
                            <div className='left-part col-lg-6 col-md-12 vh-100 d-flex align-items-center justify-content-center '>
                            </div>
                            <div className='right-part col-lg-6 col-md-12'>

                                <div className='login-title'>
                                <img src="/InnovAxess_Logo.svg" height="50px" alt="Product Logo" className="company-logo" />
                                </div>

                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        formik.handleSubmit();
                                    }} className='login-form'>
                                    <div className='field-form-group'>
                                        <label htmlFor="" className='form-label' >Email</label>
                                        <input type="text" className={formik.getFieldMeta(userEmail.Name).touched && formik.getFieldMeta(userEmail.Name).error != null ? "form-control input-error error-margin" : "form-control field-email"} name="Email" id={userEmail.Name} {...formik.getFieldProps(userEmail.Name)} />
                                        {formik.getFieldMeta(userEmail.Name).touched &&
                                            formik.getFieldMeta(userEmail.Name).error != null ? (
                                            <div className="error">
                                                {formik.getFieldMeta(userEmail.Name).error}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className='field-form-group '>
                                        <label className='form-label' htmlFor="" >Password</label>
                                        <div className='input-box'>
                                            <input
                                                type={(showpassword === false) ? 'password' : 'text'}
                                                className={
                                                    formik.getFieldMeta(userPassword.Name).touched &&
                                                        formik.getFieldMeta(userPassword.Name).error != null
                                                        ? "form-control input-error error-margin"
                                                        : "form-control field-password"
                                                }
                                                name="password" id={userPassword.Name} {...formik.getFieldProps(userPassword.Name)} />
                                            <div id="toggle" >
                                                {
                                                    (showpassword) ? <AiFillEye onClick={toggleShowPassword} /> : <AiFillEyeInvisible onClick={toggleShowPassword} />
                                                }
                                            </div>

                                        </div>

                                    </div>
                                    {formik.getFieldMeta(userPassword.Name).touched &&
                                        formik.getFieldMeta(userPassword.Name).error != null ? (
                                        <div className="error">
                                            {formik.getFieldMeta(userPassword.Name).error}
                                        </div>
                                    ) : null}
                                    <div className='d-flex justify-content-between help-links my-3'>
                                        <div className="form-check">
                                            <input className="form-check-input remember-me" onClick={rememberMe} type="checkbox" value="" id="flexCheckDefault" />
                                            <label className="form-check-label remember-label" htmlFor="flexCheckDefault">
                                                Remember me
                                            </label>
                                        </div>
                                        <Link to="/login/forgot-password">Forgot Password?</Link>
                                    </div>
                                    <button
                                        disabled={
                                            !(formik.dirty && formik.isValid) && !(formik.values.userEmail && formik.values.userPassword)
                                        }
                                        className='btn login-btn' type='submit'>Login</button>
                                </form>
                                <div className="powered-by-container">
                                    <div className="powered-by-text">Powered By</div>
                                    <img src="/IOasis_Logo.svg" alt="Company Logo" className="company-logo" />
                                </div>
                            </div>
                        </div>

                    </div>

            }
            

        </>
    )
}

export default Login
