import axios from "axios";
import ApiUtils from "./ApiUtils";

const ReportApi = {
  GetEmployeesAttendance: {
    default: {
      message: "",
      status: true,
      code: 0,
      data: [
        {
          employeeId: "",
          name: "",
          group: "",
          zone: "",
          position: "",
          company: "",
          firstCheckInTime: "",
          lastCheckOutTime: "",
        },
      ],
    },
    call: async (body) => {
      return new Promise((resolve, reject) => {
        ApiUtils.setAxiosDefaultHeaders(axios);
        
        axios
          .post(process.env.REACT_APP_API_URL + `/Reports/GetEmployeesAttendance`,body)
          .then((response) => {
            let res = ApiUtils.handleApiResponse(response);
            resolve(res);
          })
          .catch((error) => {
            reject({
              status: false,
              code: -1,
              message: error.message,
            });
          });
      });
    },
  },
  NonSignedOutEmployees: {
    default: {
      message: "",
      status: true,
      code: 0,
      data: [
        {
          
        },
      ],
    },
    call: async (body) => {
      return new Promise((resolve, reject) => {
        ApiUtils.setAxiosDefaultHeaders(axios);
        axios
          .post(process.env.REACT_APP_API_URL + `/Operation/GetMusterList?zoneId=${body}`,body)
          .then((response) => {
            let res = ApiUtils.handleApiResponse(response);
            resolve(res);
          })
          .catch((error) => {
                  reject({
                      status: false,
                      code: -1,
                      message: error.message,
                  });
              });
        });
    },
  },
};

export default ReportApi;
