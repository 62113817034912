import React, { useEffect, useRef } from "react";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import "datatables.net-responsive/js/dataTables.responsive";
import DashboardApi from "../../Api/DashboardApi";
import { ToastTemplate, ToastType } from "../../notifications/ToastExecutor";
import moment from "moment";

function EmployeeTransactionTable({ refreshRate }) {
  const dataTableRef = useRef(null);
  const dataTableData = useRef([]);

  useEffect(() => {
    if (!dataTableRef.current){
      dataTableRef.current = $('#table').DataTable({
      iDisplayLength: 10,
      language: {
        paginate: {
          previous: "<",
          next: ">",
        },
      },
      columns: [
        {
          title: ``,
          data: "id",
          visible: false,
          createdCell: function (td, cellData, rowData, row, col, tr) {
            $(td).addClass("col-edit");
          },
        },
        {
          title: `UID`,
          data: "uid",
          createdCell: function (td, cellData, rowData, row, col, tr) {
            $(td).addClass("col-edit");
          },
        },
        {
          title: `Name`,
          data: "name",
          createdCell: function (td, cellData, rowData, row, col, tr) {
            $(td).addClass("col-edit");
          },
        },
        {
          title: `Zone`,
          data: "zone",
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass("col-edit");
          },
        },
        {
          title: `Position`,
          data: "position",
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass("col-edit");
          },
        },
        {
          title: `Group`,
          data: "group",
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass("col-edit");
          },
        },
        {
          title: `Action`,
          data: "action",
          createdCell: function (td, cellData, rowData, row, col) {
            let actionText = td.textContent.toUpperCase();
            let failedActionText = actionText.endsWith('ERROR')?'FAILED' : actionText.endsWith('UNKOWN')?'UNKOWN' : '';
            if (actionText.startsWith('CHECKOUT')) {
              td.innerHTML = "<p class='checkOut mb-0'" + (failedActionText.length > 0? " style='color:hsl(56, 61%, 28%) !important;'":"") + ">" + (failedActionText.length > 0?failedActionText:'Check Out') + '</p>';
            } else if (actionText.startsWith('CHECKIN')) {
              td.innerHTML = "<p class='checkIn mb-0'" + (failedActionText.length > 0? " style='color:hsl(56, 61%, 28%) !important;'":"") + ">" + (failedActionText.length > 0?failedActionText:'Check In') + '</p>';
            }

            $(td).addClass("col-data-edit");
          },
        },
        {
          title: `Time`,
          data: "time",
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass("col-edit");
          },
          render: function (data) {
            return moment(data).format("YYYY-MM-DD hh:mm A");
          },
          type: "date",
          orderDataType: "dom-date",
        },
      ],
      dom: "<'row  '<'col-sm-12 col-md-6 d-flex '><'col-sm-6  flex-row-reverse col-md-6 d-flex flex-row-reverse mb-5'>>"+
      "tp",
      // Set initial sorting to the "Time" column in descending order
      order: [[7, "desc"], [0, "desc"]],
      columnDefs: [
        {
          defaultContent: "-",
          targets: ["_all"],
          className: "dt-body-left",
        },
        { orderable: false, targets: [0, 1, 2, 3, 4, 5, 6, 7] },
      ],      
      createdRow: function (row, data, dataIndex) {
        $(row).attr("data-id", data.id);
      },
    });

    let timeoutId;

    async function getEmployeeTransactions() {
      try {
        const latestId = dataTableData.current.reduce((maxId, row) => Math.max(maxId, row.id || 0), 0);
        const res = await DashboardApi.GetLatestEmployeeTransactions.call({ last: latestId });
        if (res.status) {
            updateDataTable(res.data);
        } else {
            // new ToastTemplate(ToastType.Error, res.message).execute();
        }
      } catch (err) {
          new ToastTemplate(ToastType.Error, err.message).execute();
      } finally {
        // Trigger the next data fetch after refreshRate interval
        timeoutId = setTimeout(getEmployeeTransactions, refreshRate);
      }
    }

    const updateDataTable = (newData) => {
      const newDataFiltered = newData.filter(
        (newRow) => !dataTableData.current.some((existingRow) => existingRow.id === newRow.id)
      );

      // Combine existing data with new data
      let combinedData = [...dataTableData.current, ...newDataFiltered];
      // Sort the combined data based on the "time" column in descending order
      combinedData.sort((a, b) => moment(b.time).valueOf() - moment(a.time).valueOf());
    
      if(newDataFiltered.length > 0){
        // Update the DataTable with the sorted data
        dataTableRef.current.rows.add(newDataFiltered).draw(false);
      }
      
      //Remove extra rows
      const excessRows = combinedData.length - 100;
      if(excessRows > 0){
        const excessIds = combinedData.slice(-excessRows).map((row) => row.id);
        const excessRowsIndexes = dataTableRef.current
          .rows((index, data, node) => excessIds.includes(data.id))
          .indexes();

        // Remove excess rows from the DataTable
        dataTableRef.current.rows(excessRowsIndexes).remove().draw(false);
        // Remove excess rows from the dataTableData
        combinedData = combinedData.slice(0, -excessRows);
      }

      // Store the sorted data in the state for future updates
      dataTableData.current = combinedData;
    };

    getEmployeeTransactions();
    
    return () => {
      // Clear the timeout when the component is unmounted
      clearTimeout(timeoutId);
    };

  }}, [refreshRate, dataTableData]);

  useEffect(() => {
    $(dataTableRef.current)
      .find("td.col-edit")
      .css({
        "font-size": "0.25rem",
        "font-weigth": "50",
        "max-width": "10px"
      });
  }, []);

  return (
    <div className="card-table table-responsive">
      <table id="table" className="display nowrap row-border" style={{ width: "100%" }}></table>
    </div>
  );
}

export default EmployeeTransactionTable;