import React, { useState } from 'react';
import CircularLoader from '../../Component/CircularLoader/CircularLoader';
import success from '../../Assets/Success.svg';
import error from '../../Assets/Error.svg';
import './ImportPage.css';

const ExcelTable = ({ columns, rows, handleColumnMappingChange, syncStatus, errors, photos }) => {
  const [columnMappings, setColumnMappings] = useState({});

  // Function to fill empty cells with empty strings
  const fillEmptyCells = (rowData, totalCells) => {
    const filledRow = [...rowData];
    while (filledRow.length < totalCells) { 
      filledRow.push('');
    }
    return filledRow;
  };
  const localHandleColumnMappingChange = (event, columnName) => {
    const { value } = event.target;
  
    if(value===''){
      setColumnMappings((prevMappings) => {
        const updatedMappings = { ...prevMappings };
        // Delete the mapping if the value is an empty string
        delete updatedMappings[columnName];
        return updatedMappings;
      });
    }else{
      // Check if the server column is already mapped
      const isColumnMapped = Object.values(columnMappings).includes(value);
    
      // If the server column is already mapped, display an error message
      if (isColumnMapped) {
        alert('This server column is already mapped to another Excel column!');
        return;
      }
    
      setColumnMappings((prevMappings) => ({
        ...prevMappings,
        [columnName]: value,
      }));
    }
    handleColumnMappingChange(event, columnName);
  };
  // Function to get the photo for a specific row based on the uid
  const getPhotoForRow = (uid) => {
    const matchingPhoto = photos.find((photo) => photo.name.startsWith(uid));
    return matchingPhoto || null;
  };

  const getSyncStatusTooltip = (syncStatus, error) => {
    if (syncStatus === 'error') {
      return error;
    }
    return '';
  };

  return (
    <div className="card-table">
      <div className="table-container">
        <table id="table" className="display row-border ">
          <thead>
            <tr>
              <th>Sync Status</th> {/* Add the Sync Status column */}
              {columns.map((column) => (
                <th key={column} className="dt-body-left">
                  {column}
                  <div>
                    <select value={columnMappings[column] || ''} onChange={(event) => localHandleColumnMappingChange(event, column)}>
                      <option value="">Select Column..</option>
                      <option value="cardSerial">Card Serial</option>
                      <option value="company">Company Name</option>
                      <option value="contractEndDate">End Date</option>
                      <option value="fullName">Full Name</option>
                      <option value="groupName">Group</option>                      
                      <option value="position">Position</option>
                      <option value="contractStartDate">Start Date</option>
                      <option value="uid">Unique ID</option>
                    </select>
                  </div>
                </th>
              ))}
              <th>Photo</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => {
              const filledRow = fillEmptyCells(row, columns.length);
              const syncStatusCell = syncStatus[rowIndex];
              const uidColumnMapping = Object.entries(columnMappings).find(([_, value]) => value === 'uid');
              const uidExcelColumn = uidColumnMapping != null?uidColumnMapping[0]:null;
              const uidIndex = columns.findIndex((column) => column === uidExcelColumn);
              const uid = filledRow[uidIndex]
              const photo = getPhotoForRow(uid);

              return (
                <tr key={rowIndex}>
                  <td>
                    <div className="status-cell" title={getSyncStatusTooltip(syncStatusCell, errors[rowIndex])}>
                      {syncStatusCell === 'loading' ? (
                        <CircularLoader size={20} />
                      ) : syncStatusCell === 'success' ? (
                        <img src={success} alt="" className="status-icon" />
                      ) : syncStatusCell === 'error' ? (
                        <img src={error} alt="" className="status-icon" />
                      ) : null}
                    </div>
                  </td>
                  {filledRow.map((cell, cellIndex) => {
                    return <td key={cellIndex}>{cell}</td>;
                  })}
                  <td>
                    {photo ? (
                      <img src={URL.createObjectURL(photo)} alt="" className="photo-thumbnail" />
                    ) : (
                      <span className="no-photo">No Photo</span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExcelTable;
