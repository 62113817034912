import axios from "axios"

export const LoginApi = {
    login: {
        call: async (userName, password) => {
            let body = {
                userName: userName,
                password: password
            }

            return new Promise((resolve, reject) => {
                axios.post(process.env.REACT_APP_API_URL + `/Authorize/GenerateToken`, body).then(res => {
                    resolve({
                        data: res.data.data,
                        status: res.data.status,
                        code: res.data.code,
                        message: res.data.message
                    })
                }).catch(error => {
                    reject(error)

                })
            })
        }
    },
    forgotPassword: {
        call: async (email) => {
            let body = {
                email,
            }

            return new Promise((resolve, reject) => {
                axios.post(process.env.REACT_APP_API_URL + `/Authorize/ForgotPassword`, body).then(res => {
                    resolve({
                        data: res.data.data,
                        status: res.data.status,
                        code: res.data.code,
                        message: res.data.message
                    })
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
    newPassword: {
        call: (token, newPassword) => {

            if(token == null){
                return new Promise((resolve, reject) => {
                    reject('No Token Found')
                })
            }      

            let body = {
                newPassword: newPassword
            }
    
            return new Promise((resolve, reject) => {
                axios.defaults.headers["Token"] = token;
                axios.put(process.env.REACT_APP_API_URL + `/Authorize/NewPassword`, body).then(res => {
                    resolve({
                        data: res.data.data,
                        status: res.data.status,
                        code: res.data.code,
                        message: res.data.message
                    })

                }).catch(error => {
                    reject(error)
                })
            })
        }
    },    
    DecodeToken: {
        call: (token) => {
            if(token == null){
                return new Promise((resolve, reject) => {
                    reject('No Token Found')
                })
            }

            let body = {
                "token": token.toString()
            }
    
            return new Promise((resolve, reject) => {
                axios.post(process.env.REACT_APP_API_URL + `/Authorize/DecodeToken`, body).then(res => {
                    resolve({
                        data: res.data.data,
                        status: res.data.status,
                        code: res.data.code,
                        message: res.data.message
                    })

                }).catch(error => {
                    reject(error)
                    LoginApi.navigateToUnauthorizedPage();
                })
            })
        }
    },
    
    navigateToUnauthorizedPage() {
        LoginApi.clearLoginCache()
        window.location = '/login/unauthorized'
    },
    navigateToLoginPage() {
        LoginApi.clearLoginCache()
        window.location = '/login'
    },
    navigateToHome() {
        window.location = '/home'
    },

    getToken() {return localStorage.getItem('token')},
    setToken(token) {localStorage.setItem('token', token) },
    getUserRole() {return localStorage.getItem('role')},
    setUserRole(role){localStorage.setItem('role', role)},
    getUserData(){return localStorage.getItem('userData')},
    setUserData(userData){localStorage.setItem('userData', userData)},
    getFriendlyName(){return localStorage.getItem('friendlyName')},
    setFriendlyName(name){localStorage.setItem('friendlyName', name)},
    
    clearLoginCache(){localStorage.removeItem("token"); localStorage.clear()},

    rememberMe() {

        if ((window.location.pathname == "/"|| window.location.pathname.startsWith("/login"))) {

            let token = LoginApi.getToken();

            if(token){

            LoginApi.DecodeToken.call(token)
            .then((res) => {
             
                 LoginApi.navigateToHome()
               
             })
                .catch((res) => {
                  
                });
            }else{
               

            }
        }
    }
}
export const requireAdminRole = () => {
    const role = LoginApi.getUserRole();
    return role === 'Admin';
};
export const requireOperatorRole = () => {
    const role = LoginApi.getUserRole();
    return role === 'Admin' || role === 'Operator';
};

export default LoginApi;

