import React from "react";
import { useState } from "react";
import {
  reportSchema,
  fromDate,
  toDate,
  zoneId,
  groupId,
} from "../../../validationSchema/reportSchema";
import { getInitialValues } from "../../../extensions/FormikExtension";
import { Formik, Field, Form, useFormik } from "formik";
import { useMemo } from "react";
import ManagementApi from "../../../Api/ManagementApi";
import ReportApi from "../../../Api/ReportApi";
import { ToastTemplate, ToastType } from "../../../notifications/ToastExecutor";
import LoadingScreen from "./../../../Component/LoadingScreen/LoadingScreen";

function FilterModal({ f }) {
  const [loading, setLoading] = useState(false);
  const [initialValues] = useState(getInitialValues([fromDate, toDate, zoneId, groupId]));
  const [zoneList, setZoneList] = useState(ManagementApi.zoneApi.zoneList.default.data);
  const [groupList, setGroupList] = useState(ManagementApi.groupApi.groupList.default.data);
  const [selectAllZones, setSelectAllZones] = useState(false);
  const [selectAllGroups, setSelectAllGroups] = useState(false);

  async function fillZones() {
    try {
      setLoading(true);
      let res = await ManagementApi.zoneApi.zoneList.call();
      if (res.status) {
        setZoneList(res.data);
        setLoading(false);
        return res.data;
      } else {
        setLoading(false);
        new ToastTemplate(ToastType.Error, res.message).execute();
      }
    } catch (err) {
      new ToastTemplate(ToastType.Error, err.message).execute();
      setLoading(false);
    }
  }

  async function fillGroup() {
    try {
      setLoading(true);
      let res = await ManagementApi.groupApi.groupList.call();

      if (res.status) {
        setGroupList(res.data);
        setLoading(false);
      } else {
        setLoading(false);
        new ToastTemplate(ToastType.Error, res.message).execute();
      }
    } catch (err) {
      new ToastTemplate(ToastType.Error, err.message).execute();
      setLoading(false);
    }
  }

  useMemo(() => {
    fillGroup();
    fillZones();
  }, []);

  const handleSelectAllZones = (e, setFieldValue) => {
    const isChecked = e.target.checked;
    setSelectAllZones(isChecked);
    setZoneList((prevZoneList) =>
      prevZoneList.map((zone) => ({
        ...zone,
        checked: isChecked,
      }))
    );
  
    const selectedZoneIds = isChecked ? zoneList.map((zone) => zone.id.toString()) : [];
    setFieldValue(zoneId.Name, selectedZoneIds);
    // Manually trigger form validation
    setFieldValue("zoneId", selectedZoneIds);
  };
  
  const handleSelectAllGroups = (e, setFieldValue) => {
    const isChecked = e.target.checked;
    setSelectAllGroups(isChecked);
    setGroupList((prevGroupList) =>
      prevGroupList.map((group) => ({
        ...group,
        checked: isChecked,
      }))
    );
  
    const selectedGroupIds = isChecked ? groupList.map((group) => group.id.toString()) : [];
    setFieldValue(groupId.Name, selectedGroupIds);
    setFieldValue("groupId", selectedGroupIds);;
  };
  

  const handleSelectZone = (e, setFieldValue) => {
    const zoneId = e.target.value;
    const isChecked = e.target.checked;
    setZoneList((prevZoneList) =>
      prevZoneList.map((zone) => {
        if (zone.id === zoneId) {
          return {
            ...zone,
            checked: isChecked,
          };
        }
        return zone;
      })
    );
    const selectedZoneIds = zoneList
      .map((zone) => {
        if (zone.id === zoneId) {
          return {
            ...zone,
            checked: isChecked,
          };
        }
        return zone;
      })
      .filter((zone) => zone.checked)
      .map((zone) => zone.id.toString());
    setFieldValue(zoneId.Name, selectedZoneIds);
    // Manually trigger form validation
    setFieldValue("zoneId", selectedZoneIds);
  
    const allZonesChecked = selectedZoneIds.length === zoneList.length;
    setSelectAllZones(allZonesChecked);
  };
  
  const handleSelectGroup = (e, setFieldValue) => {
    const groupId = e.target.value;
    const isChecked = e.target.checked;
    setGroupList((prevGroupList) =>
      prevGroupList.map((group) => {
        if (group.id === groupId) {
          return {
            ...group,
            checked: isChecked,
          };
        }
        return group;
      })
    );
    const selectedGroupIds = groupList
      .map((group) => {
        if (group.id === groupId) {
          return {
            ...group,
            checked: isChecked,
          };
        }
        return group;
      })
      .filter((group) => group.checked)
      .map((group) => group.id.toString());
    setFieldValue(groupId.Name, selectedGroupIds);
    // Manually trigger form validation
    setFieldValue("groupId", selectedGroupIds);
  
    const allGroupsChecked = selectedGroupIds.length === groupList.length;
    setSelectAllGroups(allGroupsChecked);
  };
  
  const handleReset = ({ resetForm }) => {
    resetForm({
      values: getInitialValues([fromDate, toDate, zoneId, groupId]),
      status: { selectAllZones: false, selectAllGroups: false },
    });

    setSelectAllZones(false);
    setSelectAllGroups(false);
    // Manually reset the selected zones and groups
    setZoneList((prevZoneList) =>
      prevZoneList.map((zone) => ({
        ...zone,
        checked: false,
      }))
    );
    setGroupList((prevGroupList) =>
      prevGroupList.map((group) => ({
        ...group,
        checked: false,
      }))
    );
  };
 
  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={reportSchema}
                onSubmit={(values, { resetForm }) => {
                  
                  let filterValues = {
                    From: values.fromDate,
                    To: values.toDate,
                    Zones: selectAllZones? "ALL" : values.zoneId.join(", "),
                    Groups: selectAllGroups? "ALL" : values.groupId.join(", "),
                  };
                  handleReset({ resetForm })
                  f(filterValues);
                }}
              >
                {({ touched, errors, dirty, isValid, setFieldValue }) => (
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Attendance Report Filter
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setFieldValue("fromDate", "");
                  setFieldValue("toDate", "");
                  setSelectAllZones(false);
                  setSelectAllGroups(false);
                  // Manually reset the selected zones and groups
                  setZoneList((prevZoneList) =>
                    prevZoneList.map((zone) => ({
                      ...zone,
                      checked: false,
                    }))
                  );
                  setGroupList((prevGroupList) =>
                    prevGroupList.map((group) => ({
                      ...group,
                      checked: false,
                    }))
                  );
                }}
              ></button>
            </div>
            <div className="modal-body">              
              <Form>
                <div className="field-group mb-2 w-100">
                  <label htmlFor="contractStartDate">From</label>
                  <Field
                    className={
                      touched.fromDate && errors.fromDate != null
                        ? "form-control input-error error-margin "
                        : "form-control "
                    }
                    type="date"
                    name="fromDate"
                    id={fromDate.Name}
                  />
                  {touched.fromDate && errors.fromDate != null ? (
                    <div className="error">{errors.fromDate}</div>
                  ) : null}
                </div>

                <div className="field-group mb-2 w-100">
                  <label htmlFor="toDate">To</label>
                  <Field
                    className={
                      touched.toDate && errors.toDate != null
                        ? "form-control input-error error-margin "
                        : "form-control "
                    }
                    type="date"
                    name="toDate"
                    id={toDate.Name}
                  />
                  {touched.toDate && errors.toDate != null ? (
                    <div className="error">{errors.toDate}</div>
                  ) : null}
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="field-group">
                      <div className="d-flex justify-content-between align-items-center">
                        <label htmlFor="zoneId">Zone</label>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            checked={selectAllZones}
                            onChange={(e) => handleSelectAllZones(e, setFieldValue)}
                          />
                          <label htmlFor="selectAllZones" className="form-check-label">
                            Select All
                          </label>
                        </div>
                      </div>
                      <div className="combo-checkBox">
                        {zoneList.map((zone) => {
                          return (
                            <div className="form-check">
                              <Field
                                className={
                                  touched.zoneId && errors.zoneId != null
                                    ? "form-check-input input-error error-margin "
                                    : "form-check-input "
                                }
                                value={zone.id.toString()}
                                name={zoneId.Name}
                                type="checkbox"
                                checked={zone.checked}
                                onChange={(e) => handleSelectZone(e, setFieldValue)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault"
                              >
                                {zone.name}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                      {touched.zoneId && errors.zoneId != null ? (
                        <div className="error">{errors.zoneId}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="field-group">
                      <div className="d-flex justify-content-between align-items-center">
                        <label htmlFor="groupId">Group</label>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            checked={selectAllGroups}
                            onChange={(e) => handleSelectAllGroups(e, setFieldValue)}
                          />
                          <label htmlFor="selectAllGroups" className="form-check-label">
                            Select All
                          </label>
                        </div>
                      </div>
                      <div className="combo-checkBox">
                        {groupList.map((group) => {
                          return (
                            <div className="form-check">
                              <Field
                                className={
                                  touched.groupId && errors.groupId != null
                                    ? "form-check-input input-error error-margin "
                                    : "form-check-input "
                                }
                                value={group.id.toString()}
                                name={groupId.Name}
                                type="checkbox"
                                checked={group.checked}
                                onChange={(e) => handleSelectGroup(e, setFieldValue)}
                              />

                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault"
                              >
                                {group.name}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                      {touched.groupId && errors.groupId != null ? (
                        <div className="error">{errors.groupId}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="modal-footer border-0 d-flex justify-content-center">
                  <button
                    type="submit"
                    className={!(dirty && isValid) ? "disabled-btn" : "add-modal-btn"}
                    data-bs-dismiss="modal"
                    disabled={!(dirty && isValid)}
                  >
                    Generate
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
        )}
              </Formik>
      )}
    </>
  );
}

export default FilterModal;