import React, { useEffect, useRef } from 'react';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import $ from 'jquery';
import { createRoot } from 'react-dom/client';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import LoginApi from '../../../Api/LoginApi';

export default function ZoneReport({ zoneId }) {
  const table = useRef(null);

  useEffect(() => {
    if (table.current) {
      table.current.destroy();
    }
    table.current = $('#table').DataTable({
      responsive: true,
      processing: true,
      serverSide: true,
      filter: true,
      ajax: {
        url: process.env.REACT_APP_API_URL + '/Operation/GetMusterListPage',
        type: 'POST',
        beforeSend: function (xhr) {
          // if (zoneId <= 0) {
          //   xhr.abort(); // Abort the request if filterData is empty
          // } else {
            xhr.setRequestHeader(
              "Authorization",
              LoginApi.getToken()
            );
          // }  
        },
        data: function (d) {
          // Add additional parameters for paging, sorting, and searching
          d.orderBy = d.columns[d.order[0].column].data; // Column to order by
          d.orderDir = d.order[0].dir; // Ordering direction
          d.searchText = d.search.value; // Search text
          d.zoneId = zoneId;
        },
        dataSrc: 'data', // Property name in the response JSON containing the data array
        error: function (xhr, error, thrown) {
          console.log('Server-Paging', error);
        },
      },
      language: {
        paginate: {
          previous: '<',
          next: '>',
        },
      },
      lengthMenu: [
        [10, 15, 20, 30],
        [10, 15, 20, 30],
      ],
      order: [[5, 'asc']],
      pageLength: 10,
      columns: [
        { title: 'photo',
        orderable: false, },
        {
          title: 'UID',
          data: 'employeeId',
          orderable: false,
          createdCell: function (td, cellData, rowData, row, col, tr) {
            $(td).addClass('col-edit');
            $(rowData).addClass('te-class');
          },
        },
        {
          title: 'Name',
          data: 'employeeName',
          orderable: false,
          createdCell: function (td, cellData, rowData, row, col, tr) {
            $(td).addClass('col-edit');
            $(rowData).addClass('te-class');
          },
        },
        {
          title: 'Company',
          data: 'company',
          orderable: false,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass('col-edit');
            $(rowData).addClass('te-class');
          },
        },
        {
          title: 'Position',
          data: 'position',
          orderable: false,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass('col-edit');
            $(rowData).addClass('te-class');
          },
        },
        {
          title: 'Last Check-in Time',
          data: 'lastCheckInTime',
          orderable: true,
          createdCell: function (td, cellData, rowData, row, col) {
            td.innerHTML = `<p class='checkOut-text mb-0'>${td.textContent}</p>`;
            $(td).addClass('col-edit');
          },
        },
      ],
      dom:
        "<'row   b'<'col-sm-12 col-md-6 d-flex '><'col-sm-6  flex-row-reverse col-md-6 d-flex flex-row-reverse mb-5'>>B" +
        'tp',
      buttons: [
        {
          text: '<i class="fa-solid fa-rotate-right fs-4"></i>',
          className: 're-btn ',
          action: function (e, dt, node, config) {
            dt.ajax.reload()
          },
        },
      ],
      columnDefs: [
        {
          target: [0],
          createdCell: (td, cellData, rowData, row, col) => {
            createRoot(td).render(
              <div className="d-flex align-items-center justify-content-start">
                <img
                  className="photo-thumbnail"
                  src={rowData.photo}
                  alt=""
                />
              </div>
            );
          },
        },
        {
          defaultContent: '-',
          targets: ['_all'],
          className: 'dt-body-left',
        },
      ],
    });
  }, [zoneId]);

  return (
    <>
      <div className="card-table">
        <table
          id="table"
          className="display row-border"
          style={{ width: '100%', marginTop: '40px' }}
        ></table>
      </div>
    </>
  );
}
