import { Field } from '../extensions/FormikExtension'
import * as Yup from "yup";
export var userEmail = new Field({ Name: "userEmail", InitialValue: "" })
export var userPassword = new Field({ Name: "userPassword", InitialValue: "" })
export var confirmPassword = new Field({ Name: "confirmPassword", InitialValue: "" })

export const loginSchema = Yup.object({
	userEmail: Yup.string()
    .required('Please Enter your Email'),
    
    userPassword: Yup.string()
    .required('Please Enter  Password')
})

export const forgotPasswordSchema = Yup.object({
	userEmail: Yup.string()
    .required('Please Enter your Email'),
    // .matches(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/,"Please enter a valid email 'correct@example.com'"),
})

export const resetPasswordSchema = Yup.object({
    userPassword: Yup.string().required('Please enter a password'),
    confirmPassword: Yup.string()
      .required('Please confirm your password')
      .test('passwords-match', 'Passwords do not match', function (value) {
        return value === this.parent.userPassword;
      }),
  });