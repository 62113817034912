import React, {  useState } from "react";
import { MdAdd } from "react-icons/md";

import AccessLevels from "./tables/AccessLevels";
import DeviceTable from "./tables/DeviceTable";
import ZonesTable from "./tables/ZoneTable";
import EmployeTeble from "./tables/EmployeeTable";
import {GoDeviceMobile} from "react-icons/go"
import {TbChartAreaFilled} from "react-icons/tb"
import {MdGroups2} from "react-icons/md"
import {SiOpslevel} from "react-icons/si"
import {GrFormAdd, GrSettingsOption, GrUserManager} from "react-icons/gr"
import EmployeeALTable from "./tables/EmployeeAcessLevelsTable";
import GroupTable from "./tables/GroupTable";
import UserTable from "./tables/UserTable";
import SettingsView from "./tables/SettingsView";

function Management() {
  const [activeZones, setActiveZones] = useState(true);
  const [activeDevice, setActiveDevice] = useState(false);
  const [activeEmployees, setActiveEmployees] = useState(false);
  const [activeAccessLevels, setActiveAccessLevels] = useState(false);
  const [activeGroup, setActiveGroup] = useState(false);
  const [activeUsers, setActiveUsers] = useState(false);
  const [activeSettings, setActiveSettings] = useState(false);
  
  const [editMode,setEditMode] = useState(false)
  
  function ShowElements(value) {
    setActiveZones(value === "Zones");
    setActiveDevice(value === "Devices");
    setActiveGroup(value === "Groups");
    setActiveEmployees(value === "Employees");
    setActiveAccessLevels(value === "Access Levels");
    setActiveUsers(value === "Users")
    setActiveSettings(value === "Settings");
  }

  return (
    <>  
      <div className="container-fluid">
        <div className="management rwd-m">
          <div className="row">
            <div className="col-md-12">
              <div className="home-title">
                <h1>Management</h1>
              </div>
            </div>
          </div>

          <div className="row">
            {/* Show buttons */}
            <div className="col-9 tap-btn-group">
            <button
                className={
                  activeZones
                    ? "stageBtn active text-white border-none"
                    : "stageBtn border-none"
                }
                onClick={() => {
                  ShowElements("Zones");
                }}
              >
                Zones
              </button>
              <button
                className={
                  activeDevice
                    ? "stageBtn active text-white border-none"
                    : "stageBtn border-none"
                }
                onClick={() => {
                  ShowElements("Devices");
                }}
              >
                Devices
              </button>
              <button
                className={
                  activeAccessLevels
                    ? "stageBtn active text-white border-none"
                    : "stageBtn border-none"
                }
                onClick={() => {
                  ShowElements("Access Levels");
                }}
              >
              Access Levels
              </button>
              <button
                className={
                  activeGroup
                    ? "stageBtn active text-white border-none"
                    : "stageBtn border-none"
                }
                onClick={() => {
                  ShowElements("Groups");
                }}
              >
                Groups
              </button>
              <button
                className={
                  activeEmployees
                    ? "stageBtn active text-white border-none"
                    : "stageBtn border-none"
                }
                onClick={() => {
                  ShowElements("Employees");
                }}
              >
                Employees
              </button>
              <button
                className={
                  activeSettings
                    ? "stageBtn active text-white border-none"
                    : "stageBtn border-none"
                }
                disabled = {true}
                onClick={() => {
                  ShowElements("Settings");
                }}
              >
                Settings
              </button>
              <button
                className={
                  activeUsers
                    ? "stageBtn active text-white border-none"
                    : "stageBtn border-none"
                }
                onClick={() => {
                  ShowElements("Users");
                }}
              >
                Users
              </button>
            </div>
            <div className=" col-9 rwd-icon">
            <button
                className={
                  activeZones
                    ? "stageBtn active text-white border-none"
                    : "stageBtn border-none"
                }
                onClick={() => {
                  ShowElements("Zones");
                }}
              >
                <TbChartAreaFilled/>
              </button>
              <button  className={
                  activeDevice
                    ? "stageBtn active text-white border-none"
                    : "stageBtn border-none"
                }
                onClick={() => {
                  ShowElements("Devices");
                }}><GoDeviceMobile width="50%"/></button>
<button
                className={
                  activeAccessLevels
                    ? "stageBtn active text-white border-none"
                    : "stageBtn border-none"
                }
                onClick={() => {
                  ShowElements("Access Levels");
                }}
              >
                <SiOpslevel/>
              </button>
              <button
                className={
                  activeGroup
                    ? "stageBtn active text-white border-none"
                    : "stageBtn border-none"
                }
                onClick={() => {
                  ShowElements("Groups");
                }}
              >
                <MdGroups2/>
              </button>                
              <button
                className={
                  activeEmployees
                    ? "stageBtn active text-white border-none"
                    : "stageBtn border-none"
                }
                onClick={() => {
                  ShowElements("Employees");
                }}
              >
                <GrUserManager/>
              </button>
              <button
                className={
                  activeSettings
                    ? "stageBtn active text-white border-none"
                    : "stageBtn border-none"
                }
                onClick={() => {
                  ShowElements("Settings");
                }}
                disabled = {true}
              >
                <GrSettingsOption/>
              </button>
              <button
                className={
                  activeUsers
                    ? "stageBtn active text-white border-none"
                    : "stageBtn border-none"
                }
                onClick={() => {
                  ShowElements("Users");
                }}
              >
                <GrUserManager/>
              </button>
              </div>
            {/* Add buttons */}
            <div className="col-3 text-end add-btn">
              {activeZones ? (
                <button
                  className="addBtn"
                  data-bs-toggle="modal"
                  data-bs-target="#showCreateZoneModal"
                  onClick={()=>{setEditMode(false)}}
                >
                  <MdAdd className="me-2" />
                  Add Zone
                </button>
              ) : activeAccessLevels ? (
                <button
                  name="addBtn"
                  className="addBtn"
                  data-bs-toggle="modal"
                  data-bs-target="#createLevelModal"
                  onClick={()=>{setEditMode(false)}}
                >
                  <MdAdd className="me-2" />
                  Add Access Level
                </button>
              ) : activeGroup ? (
                <button
                  className="addBtn"
                  data-bs-toggle="modal"
                  data-bs-target="#createGroupModal"
                  onClick={()=>{setEditMode("Add Device")}}
                >
                  <MdAdd className="me-2" />
                  Add Group
                </button>
              ) : activeEmployees ? (
                <button
                  className="addBtn"
                 
                  data-bs-toggle="modal"
                  data-bs-target="#addEmployeeModal"
                >
                  <MdAdd className="me-2" />
                  Add Employee
                </button>
              ) : activeUsers ? (
                <button
                  className="addBtn"
                 
                  data-bs-toggle="modal"
                  data-bs-target="#showAddUserModal"
                >
                  <MdAdd className="me-2" />
                  Add User
                </button>
              ):(
                ""
              )}
            </div>
            <div className="col-3 text-end rwd-add-btn">
              {activeZones ? (
                <button
                  className="addBtn"
                  data-bs-toggle="modal"
                  data-bs-target="#showCreateZoneModal"
                  onClick={()=>{setEditMode(false)}}
                >
                  <MdAdd  />                  
                </button>
              ) : activeAccessLevels ? (
                <button
                  Name="addBtn"
                  className="addBtn"
                  data-bs-toggle="modal"
                  data-bs-target="#createLevelModal"
                  onClick={()=>{setEditMode(false)}}
                >
                  <MdAdd  />                  
                </button>
              ) : activeGroup ? (
                <button
                  className="addBtn"
                  data-bs-toggle="modal"
                  data-bs-target="#createGroupModal"
                  onClick={()=>{setEditMode("Add Device")}}
                >
                  <MdAdd  />                  
                </button>
              ) : activeEmployees ? (
                <button
                  className="addBtn"                 
                  data-bs-toggle="modal"
                  data-bs-target="#addEmployeeModal"
             >
                  <MdAdd  />                  
                </button>
              ) : activeUsers ? (
                <button
                  className="addBtn"                 
                  data-bs-toggle="modal"
                  data-bs-target="#showAddUserModal"
             >
                  <MdAdd  />                  
                </button>
              ):("")}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {activeDevice ? (
                <DeviceTable editStatus={editMode}/>
              ) : activeZones ? (
                <ZonesTable editStatus={editMode} />
              ) : activeEmployees ? (
                <EmployeTeble editStatus={editMode} />
              ) : activeAccessLevels ? (
                <AccessLevels editStatus={editMode} />
              ) : activeGroup ? (
                <GroupTable editStatus={editMode} />
                ) : activeUsers ? (
                  <UserTable editStatus={editMode} />
                  ) : activeSettings ? (
                    <SettingsView />
                  ) : (
                    ""
                  )}
            </div>
          </div>
        </div>
      </div>
      <div className="modals">
        {/*st --- edit Group */}
        <div
          className="modal fade"
          id="editGroupModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body bg-light">
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="">
                      Assigned Access Levels for Group:
                    </label>
                    <EmployeeALTable />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <form>
                      <div className="field-group">
                        <label
                          htmlFor="AddAccessLevel"
                          className="d-block ModelNumber-label"
                        >
                          Add Access Level
                        </label>
                        <select
                          className="form-select"
                          name="AddAccessLevel"
                          id="AddAccessLevel"
                        >
                          <option value="">al1</option>
                          <option value="">al1</option>
                          <option value="">al1</option>
                        </select>
                      </div>
                      <div className="modal-footer border-0 d-flex justify-content-center">
                        <button
                          type="button"
                          className="add-modal-btn"
                          data-bs-dismiss="modal"
                        >
                          Add
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*en --- Change Access Level modal */}
      </div>
    </>
  );
}

export default Management;
