import { LoginApi } from "./LoginApi";

const ApiUtils={
    setAxiosDefaultHeaders(axios) {
        axios.defaults.headers.common["Authorization"] = LoginApi.getToken();
    },

    handleApiResponse(response) {
        let res;
        if (response.status == 200) {
          res = response.data;
        } else if (response.status == 401) {
          res = {
            status: false,
            code: -1,
            message: "Unauthorized",
          };
          LoginApi.navigateToUnauthorizedPage();
        } else {
          res = {
            status: false,
            code: -1,
            message: "An error has occured",
          };
        }
        return res;
      },
}


export default ApiUtils;