
import React, { useEffect, useState, useRef } from 'react'

import * as echarts from 'echarts';
import DashboardApi from '../../../Api/DashboardApi';

import EChartsReact from 'echarts-for-react';

var barChart;
function BarCharts({AccessPerDayData}) {


  const [chartData, setChartData] = useState(DashboardApi.AccessPerDay.default.data)
 
  
  let zoneName = AccessPerDayData.map((zone)=>{
    return zone.zoneName
  })

  function getChartOptions(AccessPerDayData) {


    return {
  
      tooltip: {
        trigger: 'item'
      },
  dataset:{
          dimensions:["zoneName","signInEmployees"],
         source: AccessPerDayData
          
    },
      xAxis:[ {
        type:'category',
        
        
        nameTextStyle: {
          fontSize: 10,
          align: "left",
          verticalAlign: "top",
          
        },
        axisLabel:{
          rotate:60
        }

      }],
  
          
      yAxis: {
        nameTextStyle: {
          align: "left",
          padding: [0, 90, 0, 0],
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      series: [
        {

         
          stack: 'Ad',
          emphasis: {
            focus: 'series'
          },
          type: 'bar'

        },
      ]
    };
  }






  return (
    <>
      <EChartsReact
        option={getChartOptions(AccessPerDayData)}
      />
    </>
  )
}

export default BarCharts
