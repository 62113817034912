import React from 'react';
import logo from '../../Assets/InnovAxess_Logo.svg';
import CircularLoader from '../CircularLoader/CircularLoader';
import './LoadingScreen.css'; // Import the CSS file for LoadingScreen styles

export default function LoadingScreen() {
  return (
    <div className='loadingScreen'>
      <div className='loadingScreen-content'>
        <div className='lodingScreen-logo-container'>
          <img src={logo} className='loadingScreen-logo' alt='' />
        </div>
        <div className='loadingScreen-loader'>
            <CircularLoader />
          </div>
      </div>
    </div>
  );
}