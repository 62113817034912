import React, { useState, useEffect, useMemo } from "react";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { createRoot } from "react-dom/client";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import deleteIcon from "../../../Assets/icon/Delete.svg";
import ManagementApi from './../../../Api/ManagementApi';
import LoadingScreen from '../../../Component/LoadingScreen/LoadingScreen';
import { FiEdit } from 'react-icons/fi';
import DeviceModal from "../com/DeviceModal";
import { ToastTemplate, ToastType } from './../../../notifications/ToastExecutor';

export default function DeviceTable() {
  const[loading,setLoading]=useState(false)
  const [deviceList,setDeviceList] = useState(ManagementApi.deviceApi.deviceList.default.data)
  const [deviceBody,setDeviceBody] = useState({})
  const [id,setId]=useState('')

 useMemo(()=>{
  fillDeviceList()
  fillZones()
 },[])
  
  async function fillDeviceList() {
    try {
      setLoading(true);
      let res = await ManagementApi.deviceApi.deviceList.call();

      if (res.status) {
        setDeviceList(res.data);
   
        setLoading(false);
      
      } else {
        new ToastTemplate(ToastType.Error,res.message).execute()
        setLoading(false);
      }
    } catch (err) {
      new ToastTemplate(ToastType.Error,err.message).execute()
      setLoading(false);
    }
  }

  async function fillZones(){
    try{
      setLoading(true)
      let res = await ManagementApi.zoneApi.zoneList.call();
      if(res.status){
            setLoading(false)
              return res.data
          }else{
            new ToastTemplate(ToastType.Error,res.message).execute()
            setLoading(false)
          }
    } catch(err) {
      new ToastTemplate(ToastType.Error,err.message).execute()
       setLoading(false)
      }
    }

    async function deleteDevice(id){
    try{
      setLoading(true)
      let res = await ManagementApi.deviceApi.deleteDevice.call(id);
      if(res.status){
         new ToastTemplate(ToastType.Success,res.message).execute()
            setLoading(false)
            fillDeviceList()
          }else{
            new ToastTemplate(ToastType.Error,res.message).execute()
            setLoading(false)
          }
    } catch(err) {
      new ToastTemplate(ToastType.Error,err.message).execute()
      setLoading(false) 
     }
  }

  useEffect(() => {
    $("#table").DataTable({
      stateSave: false,
      bDestroy: true,
  
      language: {
        paginate: {
          previous: "<",
          next: ">",
        },
      },
      lengthMenu: [
        [ 5, 10, 15, -1 ],
        [ 5, 10, 15, 'Show all' ]
    ],
      data: deviceList,

      columns: [
        {
          title: `Device Name`,
          data: "deviceName",
          searchable: true,
        orderable: true,
          createdCell: function (td, cellData, rowData, row, col, tr) {
            $(td).addClass("col-edit");
            $(rowData).addClass("te-class");
          },
        },
        {
          title: `serial Number`,
          data: "serialNumber",
          searchable: true,
        orderable: true,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass("col-edit");
          },
        },
        {
          title: `imei`,
          data: "imei",
          searchable: true,
        orderable: true,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass("col-edit");
          },
        },
        {
          title: `Zone`,
          data: "zoneName",
          searchable: true,
        orderable: true,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass("col-edit");
          },
        },
        {
          title: `Extras`,
          data: "extras",
          searchable: true,
        orderable: true,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass("col-edit");
          },
        },
        {
          title: `Last Sync`,
          data: "lastSyncTime",
          searchable: true,
        orderable: true,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass("col-edit");
          },
        },
        { title: ``,
        searchable: false,
        orderable: false, },
      ],
      dom:
      "<'row  '<'col-sm-12 col-md-6 d-flex mt-3'f><'col-sm-6  flex-row-reverse col-md-6 d-flex flex-row-reverse mb-5'B>>" +
      "t<'row  e_table'<'col-sm-12 col-md-6 d-flex align-items-end justify-content-end 'p><'col-sm-6  flex-row-reverse col-md-6 d-flex flex-row-reverse   mt-3'l>>",
      columnDefs: [
        {
          target: -1,
          createdCell: (td, cellData, rowData, row, col) => {
            createRoot(td).render(
              <div className="d-flex align-items-center justify-content-around">
                <button
                  className="deleteBtn "
                  data-bs-toggle="modal"
                  data-bs-target="#deleteModal"
                  onClick={()=>{setId(rowData.id)}}
                >
                  <img src={deleteIcon} alt="" />
                </button>
                <button
                      className="deleteBtn "
                      onClick={()=>{setDeviceBody(rowData);}}
                      data-bs-toggle="modal"
                  data-bs-target="#showEditDeviceModal"
                    >
                      <FiEdit/>
                    </button>
              </div>
            );
          },
        },

        {
          defaultContent: "-",
          targets: ["_all"],
          className: "dt-body-left",
        },
      ],

      buttons: [
       {
        text:'<i class="fa-solid fa-rotate-right"></i>',
        className:"re-btn fs-4",
        action:function(e,dt,node,config){
          fillDeviceList()
        }
       }
    ],
    initComplete: function () {
      $('#table thead tr')
        .removeClass('col-edit')
        .addClass('filters')
        .appendTo('#table thead');
  
      var api = this.api();
      // For each column
      api.columns()
        .eq(0)
        .each(function (colIdx) {
          var column = api.settings().init().columns[colIdx];
          var cell = $('.filters th').eq($(api.column(colIdx).header()).index());
          var title = column.title;
          if (!column.searchable) {  
            $(cell).html(`
              <div style="display: flex; align-items: center;">
                <span class="col-edit">${title}</span>
              </div>
            `);
          } else{// If the column is searchable, add the search input      
            // Set the header cell to contain the input element
            $(cell).html(`
            <div style="display: flex; align-items: center;">
              <span class="col-edit">${title}</span>
              <img src="/search.svg" alt="search" style="cursor: pointer; margin-left: auto;" />
            </div>
            <div class="hide clear-input-container" >
              <input type="text" placeholder="search" class="clear-input" />
              <button class="clear-input-button">X</button>
            </div>
            `);
  
            // On clicking the search icon, toggle the visibility of the input element
            $('img', cell).on('click', function (e) {
              e.preventDefault();
              e.stopPropagation();
              $('.clear-input-container', cell).toggleClass('hide');
            });
  
            $('input', cell)
            .on('input', function (e) {
              const input = $(this);
              if (input.val() && !input.hasClass("clear-input--touched")) {
                input.addClass("clear-input--touched");
              } else if (!input.val() && input.hasClass("clear-input--touched")) {
                input.removeClass("clear-input--touched");
              }
            });
            $('button', cell)
            .on('click', function (e) {
              e.stopPropagation();
              const input = $(this).siblings(".clear-input");
              input.val('');
              input.removeClass("clear-input--touched");
              input.trigger('change');
              $('.clear-input-container', cell).toggleClass('hide');
            });
            // On every keypress in this input
            $('input', cell)
                .off('keyup change')
                .on('change', function (e) {
                    // Get the search value
                   $(this).attr('title', $(this).val());
                    api.columns(colIdx).search(this.value).draw();
                })
                .on('keydown', function (e) {
                  e.stopPropagation();
                })
                .on('keyup', function (e) {
                    e.stopPropagation();
                    $(this).trigger('change');
                })
                .on('click', function (e) {
                  e.preventDefault(); // Prevent the default behavior (sorting action)
                  e.stopPropagation(); // Stop event propagation to avoid unintended side effects
                });
        }});
      },
    });
  }, [deviceList]);

  return (
    <>
    {
      loading?<LoadingScreen/>:
      <div className="card-table">
      <table id="table" className="display   row-border " style={{ "width": "100%",marginTop:"40px" }}></table>
    </div>
    }

      <div className="modal fade" id="showAddDeviceModal" tabIndex="-1" data-bs-backdrop="static"  aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <DeviceModal refreshDeviceList={()=>{fillDeviceList();}} mode={"add"}/>
      </div>

      <div className="modal fade" id="showEditDeviceModal" tabIndex="-1" data-bs-backdrop="static"  aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <DeviceModal mode={"edit"} refreshDeviceList={()=>{fillDeviceList();}} data={deviceBody}/>
      </div>

      <div
          className="modal fade"
          id="deleteModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body d-flex align-items-center justify-content-center">
                <h1>Are you sure you want to delete?</h1>
          
              </div>
              <div className="modal-footer border-0 d-flex justify-content-center">
              <button
                type="submit"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={()=>{deleteDevice(id)}}
              >
                YES
              </button>
              <button
                type="submit"
                className="btn  btn-success"
                data-bs-dismiss="modal"
              >
                NO
              </button>
            </div>
            </div>
          </div>
        </div>
    </>
  );
}