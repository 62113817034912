import axios from "axios";
import ApiUtils from "./ApiUtils"

const DashboardApi = {
  getFigures: {
    default:
    {
      data: {
        NumberOfEmployees: "0",
        NumberOfZones: 0,
        TimeSpent: 0,
      },
      status: true,
      code: 0,
      message: ""


    },

    call:  () => {
      let p =  new Promise((resolve, reject) => {
        ApiUtils.setAxiosDefaultHeaders(axios);
        axios.get(process.env.REACT_APP_API_URL + `/Statistics/GetSystemFigures`)
          .then((response) => {
        
            let res = ApiUtils.handleApiResponse(response);
            resolve(res);
          })
          .catch((error) => {
            reject({
              status: false,
              code: -1,
              message: error.message,
            });

          });       
      });
    

      return p;
    },



  },

  AccessPerDay: {
    default:
    {
      data: [{}],
      status: true,
      code: 0,
      message: ""


    },


    call: () => {
      return new Promise((resolve, reject) => {
        ApiUtils.setAxiosDefaultHeaders(axios);
        axios
          .get(process.env.REACT_APP_API_URL + `/Statistics/GetAccessPerDay`)
          .then((response) => {
            let res = ApiUtils.handleApiResponse(response);
            resolve(res);
          })
          .catch((error) => {
            reject({
              status: false,
              code: -1,
              message: error.message,
            });
          });
      });
    },


  },

  ResourcesDistribution: {
    default:
    {
      data: {
        inSite: 0,
        outSite: 0,

      },
      status: true,
      code: 0,
      message: ""


    },


    call:  () => {
      return new Promise((resolve, reject) => {
        ApiUtils.setAxiosDefaultHeaders(axios);
        axios
          .get(process.env.REACT_APP_API_URL + `/Statistics/GetResourcesDistribution`)
          .then((response) => {
            let res = ApiUtils.handleApiResponse(response);
            resolve(res);
          })
          .catch((error) => {
            reject({
              status: false,
              code: -1,
              message: error.message,
            });
          });
      });
    },

    },
    GetLatestEmployeeTransactions: {
      default: {
        data: [
          {
            id: "",
            employeeId: "",
            name: "",
            zone: "",
            position: "",
            company: "",
            action: "",
            time: "",
          },
        ],
        status: true,
        code: 0,
        message: "",
      },
      call: async (params) => {
        return new Promise((resolve, reject) => {
            ApiUtils.setAxiosDefaultHeaders(axios);
            axios
                .get(process.env.REACT_APP_API_URL + `/Reports/GetLatestEmployeeTransactions`, { params })
                .then((response) => {
                    let res = ApiUtils.handleApiResponse(response);
                    resolve(res);
                })
                .catch((error) => {
                    reject({
                        status: false,
                        code: -1,
                        message: error.message,
                    });
                });
          });
        },
    },
    GetMobileAppLink: {
      default: {
          data: "",
          status: true,
          code: 0,
          message: "",
        },
        call: async (body) => {
          return new Promise((resolve, reject) => {           
            ApiUtils.setAxiosDefaultHeaders(axios);
            axios.get(process.env.REACT_APP_API_URL + `/Authorize/GetAndroidAppLink/${body}`)
              .then((response) => {
                let res = ApiUtils.handleApiResponse(response);
                resolve(res);
              })
              .catch((error) => {
                reject({
                  status: false,
                  code: -1,
                  message: error.message,
                });
              });
          });
        },
      },


}

export default DashboardApi;




