import React, { useState } from 'react';
import { read, utils } from 'xlsx';
import ExcelTable from './ExcelTable';
import { ToastTemplate, ToastType } from './../../notifications/ToastExecutor';
import ManagementApi from './../../Api/ManagementApi';
import CircularLoader from '../../Component/CircularLoader/CircularLoader';
import moment from 'moment';
import excelIcon from '../../Assets/excel_icon.svg'
import photosIcon from '../../Assets/photos-upload_icon.svg'

export default function ImportPage() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [columnMappings, setColumnMappings] = useState({});
  const [tableData, setTableData] = useState({ columns: [], rows: [] });
  const [syncStatus, setSyncStatus] = useState({});
  const [syncErrors, setSyncErrors] = useState({});
  const [photos, setPhotos] = useState([]);
  const [photoUploadEnabled, setPhotoUploadEnabled] = useState(false);
  const [isLargeFile, setIsLargeFile] = useState(false);


  const handleFileUpload = (event) => {
    event.preventDefault();
    setTableData({ columns: [], rows: [] }); // Reset tableData state
    setPhotoUploadEnabled(false);
    setColumnMappings({});
    setSyncErrors({});

    const file = event.target.files[0];
    if(file == null) return;

    try {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = utils.sheet_to_json(worksheet, { header: 1, raw: false });
        const rowCount = jsonData.length - 1; // Exclude the header row

        // Set isLargeFile state based on the number of rows
        setIsLargeFile(rowCount > 100);

        // Continue with your logic if the file is valid Excel
        setSelectedFile(file);
        parseExcelFile(file);
      };

      reader.readAsArrayBuffer(file);
    } catch (error) {
      // Handle the exception gracefully
      console.error(error);
      new ToastTemplate(ToastType.Error, 'Invalid file format.').execute();
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!mandatoryColumnsMapped) {
      // Display an error message or show a toast notification
      new ToastTemplate(ToastType.Error, 'Forgot to assign mandatory column mapping!').execute();
      return;
    }
    if (isLargeFile) {
      // Prepare the data to be uploaded
      const preparedData = await prepareDataForLargeFile();
  
      // Call the API to upload the preparedData and corresponding photos
      await callAPILargeFile(preparedData);
    } else {
    for (let i = 0; i < tableData.rows.length; i++) {
      const row = tableData.rows[i];
      const preparedData = await prepareDataForRow(row);

      // Set the sync status to loading for the current row
      setSyncStatus((prevSyncStatus) => ({
        ...prevSyncStatus,
        [i]: 'loading',
      }));

      // Call the API to upload the preparedData and corresponding photo
      await callAPI(preparedData, i); // Pass the index as an argument
    }
  }
  };

  const handleColumnMappingChange = (event, columnName) => {
    const { value } = event.target;

    if(value===''){
      setColumnMappings((prevMappings) => {
        const updatedMappings = { ...prevMappings };
        // Delete the mapping if the value is an empty string
        delete updatedMappings[columnName];
        return updatedMappings;
      });
    }
    else{
      setColumnMappings((prevMappings) => {
        const updatedMappings = { ...prevMappings };
        updatedMappings[columnName] = value;
        return updatedMappings;
      });
    }
  
    // Enable photo upload if UID column is mapped
    setPhotoUploadEnabled(value === 'uid' || Object.values(columnMappings).includes('uid'));
  };
  
  const prepareDataForLargeFile = async () => {
    // Create a new FormData object
  const formData = new FormData();

  // Append the Excel file to the form data
  formData.append('excelFile', selectedFile);

  // Append the column mappings as JSON to the form data
  formData.append('columnMappings', JSON.stringify(columnMappings));

  // Append the photos to the form data
  for (let i = 0; i < photos.length; i++) {
    formData.append('photos', photos[i]);
  }
  
    return formData;
  };
  
  const callAPILargeFile = async (data) => {
    try {
      // Set the loading state for the whole file
      setSyncStatus('loading');
  
      const res = await ManagementApi.employeesApi.importLargeFile.call(data);
  
      if (res.status) {
        // Display a success message or show a toast notification
        new ToastTemplate(ToastType.Success, 'Data Imported Successfully!').execute();
      } else {
        // Display an error message or show a toast notification
        new ToastTemplate(ToastType.Error, res.message).execute();
        return;
      }

      // Clear the form and reset the state
      document.getElementById("excelUpload").value = "";
      setSelectedFile(null);
      document.getElementById("photoUpload").value = "";
      setPhotos([]);
      setColumnMappings({});
      setTableData({ columns: [], rows: [] });
      setSyncStatus({});
      setSyncErrors({});
      setPhotoUploadEnabled(false);
      setIsLargeFile(false);
    } catch (error) {
      // Display an error message or show a toast notification
      new ToastTemplate(ToastType.Error, 'Import Failed!').execute();
      console.error(error);
      // Reset the loading state
      setSyncStatus('');  
    }    
  };
  
  const handlePhotoUpload = async (event) => {
    const uploadedPhotos = Array.from(event.target.files);

    // Validate each uploaded photo
    const isValidPhotos = uploadedPhotos.every((photo) => {
      // Check photo format (only allow image files)
      const allowedFormats = ['image/jpeg', 'image/png', 'image/gif'];
      if (!allowedFormats.includes(photo.type)) {
        // Display an error message or show a toast notification
        new ToastTemplate(ToastType.Error, `Invalid file format: ${photo.name}`).execute();
        return false;
      }
      return true;
    });

    if (isValidPhotos) {
      // // Resize and convert each photo before adding it to the photos array
      // const resizedPhotos = await Promise.all(
      //   uploadedPhotos.map(async (photo) => {
      //     const resizedPhoto = await resizeAndConvertImage(photo);
      //     const resizedPhotoFileName = photo.name.split('.').slice(0, -1).join('.') + '.jpg';
      //     return new File([resizedPhoto], resizedPhotoFileName, { type: 'image/jpeg' });
      //   })
      // );
      //setPhotos(resizedPhotos);
      setPhotos(uploadedPhotos);
    } else {
      // Clear the selected photos
      event.target.value = null;
    }
  };

  const resizeAndConvertImage = (imageFile) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(imageFile);

      img.onload = () => {
        // Set the maximum width and height for the photo
        const maxWidth = 200;
        const maxHeight = 200;

        // Calculate the new dimensions while maintaining the aspect ratio
        let newWidth, newHeight;
        if (img.width > img.height) {
          newWidth = maxWidth;
          newHeight = (img.height / img.width) * maxWidth;
        } else {
          newWidth = (img.width / img.height) * maxHeight;
          newHeight = maxHeight;
        }

        // Create a canvas element to draw the resized image
        const canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;
        const ctx = canvas.getContext('2d');

        // Draw the resized image on the canvas
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        // Convert the canvas image to a Blob object
        canvas.toBlob((blob) => {
          resolve(blob);
        }, 'image/jpeg');
      };

      img.onerror = (error) => {
        reject(error);
      };
    });
  };

  const parseExcelFile = (file) => {
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = utils.sheet_to_json(worksheet, { header: 1, raw: false });
  
      // Convert datetime strings to Date objects
      const formattedData = jsonData.map((row) => {
        return row.map((cell) => {
          try{
            if (typeof cell === 'string' && cell.includes('/')) {
              const formattedDate = moment(cell, 'MM/DD/YYYY hh:mm A');

              if (formattedDate.isValid()) {
                return formattedDate.format('YYYY/MM/DD');
              }
            }
          }
          catch(exception){
            console.log(exception)
          }
          return cell;
        });
      });
  
      const columns = formattedData[0];
      const rows = formattedData.slice(1);
  
      setTableData({ columns, rows });
  
      // Initialize sync status for each row
      const initialSyncStatus = {};
      for (let i = 0; i < rows.length; i++) {
        initialSyncStatus[i] = 'pending';
      }
      setSyncStatus(initialSyncStatus);
    };
    fileReader.readAsArrayBuffer(file);
  };
  

  const prepareDataForRow = async(row) => {
    const preparedData = {};

    for (let i = 0; i < tableData.columns.length; i++) {
      const excelColumn = tableData.columns[i];
      const serverColumn = columnMappings[excelColumn];
      const cellValue = row[i];

      if (serverColumn && cellValue !== null && cellValue !== '') {
        preparedData[serverColumn] = cellValue;
      }
    }

    const uidColumnMapping = Object.entries(columnMappings).find(([_, value]) => value === 'uid');
    const uidExcelColumn = uidColumnMapping != null?uidColumnMapping[0]:null;
    const uidIndex = tableData.columns.findIndex((column) => column === uidExcelColumn);
    const uid = row[uidIndex];
    const photo = photos.find((photo) => photo.name.startsWith(uid)) || null;
    if (photo != null) {
      const readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64 = reader.result;
            resolve(base64?.substr(base64?.indexOf(",") + 1) || "");
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      };
    
      const file = photo;
      await readFileAsBase64(file)
        .then((base64) => {
          preparedData.photo = base64;
        })
        .catch((error) => {
          console.error(error);
        });
    }
    
    const extras = {};
    for (let i = 0; i < tableData.columns.length; i++) {
      const excelColumn = tableData.columns[i];
      const serverColumn = columnMappings[excelColumn];
      const cellValue = row[i];

      if (!serverColumn && cellValue !== null && cellValue !== '') {
        extras[excelColumn] = cellValue;
      }
    }

    if (Object.keys(extras).length > 0) {
      preparedData.extras = JSON.stringify(extras);
    }

    return preparedData;
  };

  const callAPI = async (data, i) => {
    let errorMessage = '';
    try {
      // Set the loading state for the current row
      setSyncStatus((prevSyncStatus) => ({
        ...prevSyncStatus,
        [i]: 'loading',
      }));      
      let res = await ManagementApi.employeesApi.import.call(data);

      if (res.status) {
        // Set the success state for the current row
        setSyncStatus((prevSyncStatus) => ({
          ...prevSyncStatus,
          [i]: 'success',
        }));
      } else {
        errorMessage = res.message
        // Set the error state for the current row
        setSyncStatus((prevSyncStatus) => ({
          ...prevSyncStatus,
          [i]: 'error',
        }));
      }
    } catch (error) {
      errorMessage = error.message
      // Set the error state for the current row
      setSyncStatus((prevSyncStatus) => ({
        ...prevSyncStatus,
        [i]: 'error',
      }));
    }

    setSyncErrors((prevError)=>({
      ...prevError,
      [i]: errorMessage,
    }));
  };

  // Check if no more rows are syncing
  const allRowsSynced = Object.values(syncStatus).every((status) => status !== 'loading');
  // Check if sync has started on the current loaded excel file!
  const syncStarted = Object.values(syncStatus).some((status) => status !== 'pending');
  const mandatoryColumnsMapped = Object.values(columnMappings).some((value) => value === 'uid') 
                              && Object.values(columnMappings).some((value) => value === 'groupName')
                              && Object.values(columnMappings).some((value) => value === 'cardSerial');

  return (
    <div className="container-fluid">
      <div className="management rwd-m">
        <div className="row">
          <div className="col-md-12">
            <div className="home-title">
              <h1>Import Data</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <form onSubmit={handleFormSubmit}>
            <div className="upload-container">
            <div className={`import-Upload-div${(syncStarted && !allRowsSynced) ? ' disabled' : ''}`}>
                <input
                  type="file"
                  id="excelUpload"
                  onChange={handleFileUpload}
                  style={{ display: 'none' }}
                />
                <img src={excelIcon} alt="Excel" width="100%" height="100%" onClick={() => document.getElementById('excelUpload').click()} />
                <label htmlFor="excelUpload" className="upload-photo-label" >
                  {selectedFile === null ? 'Open Excel' : selectedFile.name}
                </label>
              </div>
              <div className={`import-Upload-div${(!photoUploadEnabled || (syncStarted && !allRowsSynced)) ? ' disabled' : ''}`}>
                <input
                  type="file"
                  id="photoUpload"
                  onChange={handlePhotoUpload}
                  accept="image/*"
                  multiple
                  style={{ display: 'none' }}
                />
                <img src={photosIcon} alt="Photos" width="100%" height="100%" onClick={() => document.getElementById('photoUpload').click()} />
                <label htmlFor="photoUpload" className="upload-photo-label">
                  {photos.length === 0 ? 'Open Photos' : photos.length + " Photos"}
                </label>
              </div>

              <button type="submit" disabled={!selectedFile || syncStarted || !mandatoryColumnsMapped} >
              {syncStarted ? (
                isLargeFile ? (
                  'Uploading...'
                ) : (
                  allRowsSynced ? (
                    'All Rows Synced'
                  ) : (
                    <div className="status-cell">
                      <CircularLoader size={20} />
                      Syncing
                    </div>
                  )
                )
              ) : (
                'Upload'
              )}
            </button>
            </div>
          </form>
        </div>
        <div className="row">
          <div className="col-12">
            {tableData.columns.length > 0 && (
              <ExcelTable
                columns={tableData.columns}
                rows={tableData.rows}
                handleColumnMappingChange={handleColumnMappingChange}
                syncStatus={syncStatus}
                errors={syncErrors}
                photos={photos}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}