import { Field } from '../extensions/FormikExtension'
import * as Yup from "yup";
export var uid = new Field({ Name: "uid", InitialValue: "" })
export var fullName = new Field({ Name: "fullName", InitialValue: "" })
export var position = new Field({ Name: "position", InitialValue: "" })
export var company = new Field({ Name: "company", InitialValue: "" })
export var cardSerial = new Field({ Name: "cardSerial", InitialValue: "" })
export var contractStartDate = new Field({ Name: "contractStartDate", InitialValue: "" })
export var contractEndDate = new Field({ Name: "contractEndDate", InitialValue: ""})
export var photo = new Field({ Name: "photo", InitialValue: ""})
export var groupId = new Field({ Name: "groupId", InitialValue: ""})
export var individualAccessLevelIds = new Field({ Name: "individualAccessLevelIds", InitialValue: []})

export const EmployeeSchema = Yup.object({
    uid: Yup.string(),//.required("Please Enter Unique ID"),
    fullName: Yup.string().required("Please Enter full name"),
    position: Yup.string(),//.required("Please Enter position"),
    company: Yup.string(),//.required("Please Enter company name"),
    cardSerial: Yup.string().required("Please Enter card Serial"),
    contractStartDate: Yup.string(),
    contractEndDate: Yup.string(),
    photo: Yup.mixed().test("fileSize", "File size cannot exceed 100 KB", (value) => {
      if (!value) return true; // Allow empty values
    
      const maxSizeInBytes = 100 * 1024; // 100 KB
      const base64String = value.split(",")[1]; // Extract the base64 data without the MIME type
    
      if (!base64String) return true; // Allow empty base64 string
    
      const fileSizeInBytes = (base64String.length * 3) / 4 - base64String.endsWith("==") ? 2 : base64String.endsWith("=") ? 1 : 0;
      return fileSizeInBytes <= maxSizeInBytes;
    }),
    groupId: Yup.string().required("Please Enter group id"),
    individualAccessLevelIds:  Yup.array()//.min(1).required("Please select individual Access Level Ids")
})