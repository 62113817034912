import { Field } from '../extensions/FormikExtension'
import * as Yup from "yup";
export var accessLevelName = new Field({ Name: "accessLevelName", InitialValue: "" })
export var shiftStartTime = new Field({ Name: "shiftStartTime", InitialValue: "" })
export var shiftEndTime = new Field({ Name: "shiftEndTime", InitialValue: "" })
export var availableZones = new Field({ Name: "availableZones", InitialValue: "" })
export var selectedDays = new Field({ Name: "selectedDays", InitialValue: []})


export const AccessLevelSchema = Yup.object({
	accessLevelName: Yup.string()
    .required('Please Enter Access level name'),

    shiftStartTime: Yup.string()
    .required('Please Enter shift start time'),
    shiftEndTime: Yup.string().required('Please Enter Shift end time'),
    availableZones: Yup.string()
    .required('Please select zone'),

    selectedDays:  Yup.array().min(1).required()   
})