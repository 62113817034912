import React, { useState } from 'react';
import { LoginApi } from '../../Api/LoginApi';
import './login.css';
import { useFormik } from 'formik'
import { getInitialValues } from '../../extensions/FormikExtension'
import { forgotPasswordSchema, userEmail, } from '../../validationSchema/LoginSchema';
import { ToastTemplate, ToastType } from './../../notifications/ToastExecutor';
import success from '../../Assets/Success_Email.svg';

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  function handleFormSubmit() {
    setIsLoading(true);

      // Call the API to initiate the password reset process
      LoginApi.forgotPassword.call(formik.getFieldMeta(userEmail.Name).value).then((res) => {
        if (res.status) {
        // Success: Redirect to a confirmation page or display a success message
        new ToastTemplate(ToastType.Success, "Please check you email!").execute()

        setIsEmailSent(true);
      } else {
        // Error: Display an error message based on the response
        new ToastTemplate(ToastType.Error, res.message).execute()
      }
      setIsLoading(false);
    })
    .catch((error) => {
      new ToastTemplate(ToastType.Error, error.response.data.message).execute()
      setIsLoading(false)
  })
}

  const formik = useFormik({
    initialValues: getInitialValues([userEmail]),
    validationSchema: forgotPasswordSchema,
    onSubmit: async () => {
      handleFormSubmit()
    }
})

  return (
    <div className='login'>
	<div className='row'>
		<div className='left-part col-lg-6 col-md-12 vh-100 d-flex align-items-center justify-content-center '>
		</div>
		<div className='right-part col-lg-6 col-md-12'>
			<div className='login-title'>
			<img src="/InnovAxess_Logo.svg" alt="Product Logo" className="company-logo" />
			</div>
      {isEmailSent ? (
      <div className="d-block">
        <img src={success} alt="Success" className="success-logo" />
        <div className='success-message'>
          <p></p>
          <p>Please check your email for instructions</p>
        </div>
      </div>
    ) : (
			<form onSubmit={(e) => {
                                  e.preventDefault();
                                  formik.handleSubmit();
                              }}
                className='login-form'>
        <h3>Forgot Password</h3>
				<div className='field-form-group'>
					<label htmlFor="" className='form-label' >Email</label>
					<input type="text" className={formik.getFieldMeta(userEmail.Name).touched && formik.getFieldMeta(userEmail.Name).error != null ? "form-control input-error error-margin" : "form-control field-email"} name="Email" id={userEmail.Name} {...formik.getFieldProps(userEmail.Name)} />
					{formik.getFieldMeta(userEmail.Name).touched &&
						formik.getFieldMeta(userEmail.Name).error != null ? (
						<div className="error">
							{formik.getFieldMeta(userEmail.Name).error}
						</div>
					) : null}
				</div>
				<button
					disabled={isLoading ||
						!(formik.dirty && formik.isValid) && !(formik.values.userEmail)
					}
					className='btn login-btn' type='submit'>{isLoading ? 'Sending Email...' : 'Send Reset Email'}</button>
			</form>
       )}
			<div className="powered-by-container">
				<div className="powered-by-text">Powered By</div>
				<img src="/IOasis_Logo.svg" alt="Company Logo" className="company-logo" />
			</div>
		</div>
	</div>

</div>
  );
};

export default ForgotPassword;
